import BigNumber from "bignumber.js"
import { ILayer, ITraitImage, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"

interface ITraitRarityGauge {
    imageId: string,
    layerId: string,
    weight: number,
    layer: ILayer[],
    changeRarity: (layerId: string, imageId: String, newWeight: number, layer: ILayer[]) => void
}
const TraitRarityGauge = ({ imageId, layerId, weight, layer, changeRarity }: ITraitRarityGauge) => {
    const itemVal = (+new BigNumber(weight).multipliedBy(100).precision(6));
    return (
        <TraitRarityGaugeStyle>
            <input value={itemVal}
            type="number"
                className="rgs_input"
                onChange={(e) => {
                    if (+e.target.value >= 0 && +e.target.value <= 100) {
                        if (e.target.value === '') {
                            changeRarity(layerId, imageId, 0, layer)
                        } else {
                            changeRarity(layerId, imageId, +e.target.value / 100, layer)
                        }

                    }
                }}
            />
            <input type="range"
                min="0"
                max="100"
                step="0.01"
                value={itemVal}
                className="rga_gauge"
                onChange={(e) => {
                    changeRarity(layerId, imageId, +e.target.value / 100, layer)
                }}
            />
        </TraitRarityGaugeStyle>
    )
}
export default TraitRarityGauge
const TraitRarityGaugeStyle = memo(styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0 20px;
    .rgs_input{
        width: 120px;
        height: 30px;
        background: #f1f1f1;
        border-radius: 5px;
        padding: 3px 5px;
        border: solid 1px #f1f1f1;
    }
    .rga_gauge{
        flex:1 1 0;
    }
`)
import { ILayer, ITraitImage, ITraits, TDimen } from "contexts/NFTGeneratorContext";
export type TImageType = "image/png" | "image/jpg" | "image/svg"
interface IRenderCanvasImage {
    imageSet: ITraits[],
    layer: ILayer[],
    id: string,
    imageType?: TImageType,
    size?: TDimen
}
interface IRenderCanvasImageWithBlob{
    nftImages: string[],
    id: string,
    imageType?: TImageType,
    size?: TDimen
}
// imageType?: TImageType = "image/png",
// size?: [number, number] = [500, 500]
export const renderCanvasImage = ({
    imageSet,
    layer,
    id,
    imageType = "image/png",
    size = [500, 500]
}: IRenderCanvasImage) => {
    let canvas = document.getElementById(id);
    (canvas as any).width = size[0];
    (canvas as any).height = size[1];
    const ctx = (canvas as any).getContext("2d");
    for (let i = 0; i < imageSet.length; i++) {
        let imageObj = new Image();
        const indexedLayer = layer.findIndex((item: ILayer) => item.id === imageSet[imageSet.length - 1 - i].layer);
        const indexedImage = layer[indexedLayer].images.findIndex((item: ITraitImage) => item.id === imageSet[imageSet.length - 1 - i].id);
        imageObj.src = layer[indexedLayer].images[indexedImage].image;
        ctx.drawImage(imageObj, 0, 0, size[0], size[1]);
    }
    const img = (canvas as any).toDataURL(imageType);
    return img
}

export const renderCanvasImageWithBlob = ({
    nftImages,
    id,
    imageType = "image/png",
    size = [500, 500]
}: IRenderCanvasImageWithBlob) => {
    let canvas = document.getElementById(id);
    const reverse = nftImages.reverse();
    (canvas as any).width = size[0];
    (canvas as any).height = size[1];
    const ctx = (canvas as any).getContext("2d");
    ctx.clearRect(0, 0, size[0], size[1]);
    for (let i = 0; i < reverse.length; i++) {
        let imageObj = new Image();
        imageObj.src = reverse[i]
        ctx.drawImage(imageObj, 0, 0, size[0], size[1]);
    }
    const img = (canvas as any).toDataURL(imageType);
    return img
}

import { memo, useRef } from "react"
import styled from "styled-components"

interface IInput {
    inputType?: "text" | "number" | "address" | "text-area";
    value: string,
    status?: "" | "negative" | "positive",
    errorText?: string,
    width?: string,
    styles?: string,
    disabled?: boolean,
    placeholder?: string,
    id?: string,
    pattern?: string,
    changeValue?: (data: string) => void,
    focusEnter?: () => void,
}
const Input = ({
    inputType = 'text',
    value,
    status = '',
    errorText = '',
    width = '100%',
    styles,
    disabled = false,
    placeholder = '',
    id,
    pattern = "[0-9]*",
    changeValue,
    focusEnter
}: IInput) => {
    const inputRef = useRef(null as any)
    return (
        <InputWrap>
            {inputType === 'number' ?
                <input
                    pattern={pattern}
                    onChange={(e: any) => {
                        if ((e.target.value.match(/^[0-9]*$/) !== null)) {
                            changeValue && changeValue(e.target.value)
                        }
                    }}
                    id={id}
                    value={value}
                    ref={inputRef}
                    disabled={disabled}
                    placeholder={placeholder}
                    onKeyDown={(event: any) => {
                        if (event.key === 'Enter') {
                            focusEnter ? focusEnter() : inputRef.current.blur();
                        }
                    }}
                />
                :
                <input
                    id={id}
                    value={value}
                    ref={inputRef}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={(e: any) => { changeValue && changeValue(e.target.value) }}
                    onKeyDown={(event: any) => {
                        if (event.key === 'Enter') {
                            focusEnter ? focusEnter() : inputRef.current.blur();
                        }
                    }}
                />
            }

        </InputWrap>
    )
}
export default Input
const InputWrap = memo(styled.div`
    input{
        width:100%;
        height:30px;
        background:#f1f1f1;
        border-radius:5px;
        padding:3px 5px;
        border:solid 1px #f1f1f1;
        &.error{
            border-color:#de0b00;
        }
    }
`)

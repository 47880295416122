import { memo, useEffect, useState } from "react"
import styled from "styled-components"
import GCHeader from "./header"
import GCList from "./list"
import Pagination from "conponents/core/Pagination"
interface IGenerateCollection {
    isFilting: boolean,
    generating: boolean,
    collection: any
}
const GenerateCollection = ({ isFilting, generating, collection }: IGenerateCollection) => {
    const [dataPage, setDataPage] = useState({
        page: 0,
        pageSize: 20,
        total: 0
    })
    useEffect(() => {
        const newCollection = Object.keys(collection);
        let page = dataPage.page,
            pageSize = dataPage.pageSize,
            total = dataPage.total;
        if (isFilting) {
            page = 0;
            total = newCollection.length;
        }else{
            if (Math.ceil(newCollection.length / pageSize) < page) {
                page = Math.ceil(newCollection.length / pageSize)
            }
        }
        setDataPage({ page, pageSize, total: newCollection.length })
    }, [collection, isFilting])
    return (
        <GenerateCollectionStyle>
            <div className="gcs_head">
                <GCHeader
                    collection={collection}
                    page={dataPage.page}
                    pageSize={dataPage.pageSize}
                    total={dataPage.total}
                    changePage={(newPage: number) => setDataPage({
                        ...dataPage,
                        page: newPage - 1
                    })}
                    changePageSize={(newPageSize: number) => setDataPage({
                        ...dataPage,
                        pageSize: newPageSize
                    })}
                />
            </div>
            <div className="gcs_list">
                <GCList
                    collection={collection}
                    page={dataPage.page}
                    pageSize={dataPage.pageSize}
                    total={dataPage.total}
                    changePage={(newPage: number) => setDataPage({
                        ...dataPage,
                        page: newPage - 1
                    })}
                />
            </div>
            <div className="gcs_pagi">
                <Pagination
                    currentPage={dataPage.page + 1}
                    totalPage={Math.ceil(dataPage.total / dataPage.pageSize)}
                    setPage={(newPage: number) => setDataPage({
                        ...dataPage,
                        page: newPage - 1
                    })}
                />
            </div>
        </GenerateCollectionStyle>
    )
}
export default GenerateCollection
const GenerateCollectionStyle = memo(styled.div`
    padding:20px;
    height:100%;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .gcs_head{

    }
    .gcs_list{
        flex: 1 1 0;
        margin:30px 0;
        overflow-y: auto;
    }
`)
import { IPrototype, IPrototypeItem, ITraits, NFTGeneratorContext } from "contexts/NFTGeneratorContext";
import { splitNameImage } from "helpers/splitNameImage";
import { Fragment, memo, useContext, useRef } from "react"
import styled, { css } from "styled-components"
import { MdAdd } from 'react-icons/md'
interface ITraitItem {
    type: 'item' | 'add';
    layertId: string;
    id?: string;
    name?: string;
    image?: string;
    weight?: number
}
const TraitItem = ({ type, layertId, id, name, image, weight }: ITraitItem) => {
    const { state: { layer, collection, prototypes }, onUpdateImages, onUpdatePrototypes } = useContext(NFTGeneratorContext)
    const uploadRef = useRef(null as HTMLInputElement | null)
    //TODO Select trait in layer ko theo thứ tự ảnh sinh ra bị sai
    // console.log('prototypes', id, prototypes, layertId, layer)
    return (
        <TraitItemStyle type={type}>
            <div className={`tis ${type === 'item' &&
                (prototypes?.active
                    && prototypes.active?.traits.findIndex((item: any) => item.id === id) > -1
                ) ? 'active' : ''}`}
                onClick={() => {
                    if (type === 'item' && id && prototypes) {
                        let prototypesObj = { ...prototypes.active } as IPrototypeItem;
                        let tempArr = [...prototypesObj.traits] as ITraits[];
                        const tempList = [...prototypes.list];
                        const indexedTrait = tempArr.findIndex((item: ITraits) => item.id === id);
                        const indexedLayer = tempArr.findIndex((item: any) => item.layer === layertId);
                        if (indexedTrait > -1) {
                            tempArr.splice(indexedTrait, 1);
                            tempList.splice(
                                tempList.findIndex((item: IPrototypeItem) => item.id === prototypesObj.id), 1
                            )
                        } else {
                            if (indexedLayer > -1) {
                                tempArr[indexedLayer].id = id;
                            } else {
                                tempArr.splice(
                                    layer.findIndex((item: any) => item.id === layertId) || 0, 0,
                                    { layer: layertId, id: id }
                                )
                            }
                            // console.log('prototypesObj', prototypesObj)
                            tempList.splice(
                                tempList.findIndex((item: IPrototypeItem) => item.id === prototypesObj.id) || 0, 1, prototypesObj
                            )
                        }
                        onUpdatePrototypes({
                            ...prototypes,
                            active: {
                                ...prototypesObj,
                                traits: tempArr
                            },
                            list: tempList
                        })

                        // let prototypesObj = { ...prototypes.active } as IPrototypeItem;
                        // let tempArr = [...prototypesObj.traits] as ITraits[];
                        // if (tempArr.findIndex((item: any) => item.layer === layertId) > -1) {
                        //     tempArr[tempArr.findIndex((item: any) => item.layer === layertId)].id = id;
                        // } else {
                        //     tempArr.splice(
                        //         layer.findIndex((item: any) => item.id === layertId) || 0, 1,
                        //         { layer: layertId, id: id }
                        //     )
                        // }
                        // const tempList = [...prototypes.list];
                        // tempList.splice(
                        //     tempList.findIndex((item: IPrototypeItem) => item.id === prototypesObj.id) || 0, 1, prototypesObj
                        // )
                    } else { uploadRef?.current?.click() }
                }}>
                <div className="tis_item">
                    <div className="tis_img">
                        {type === 'add' ? <MdAdd size={30} /> : <img src={image} />}
                    </div>
                    <p className="size-0">{type === 'add' ? 'Add trait' : name}</p>
                </div>
            </div>
            <input
                ref={uploadRef}
                type="file"
                id={`file_${layertId}`}
                className='tis_file'
                accept='.png'
                multiple
                onChange={(e: any) => {
                    const index = layer.findIndex((item: any) => item.id === layertId);
                    let possileOutcome = layer[index].weight * collection.supply,
                        baseUnit = 50;
                    const files = e.target.files;
                    // console.log('layer', layer, layertId, id)
                    let filelist = [...layer[index].images] as any;
                    if (files) {
                        const baseTotal = baseUnit * filelist.length;
                        const newlyAddBaseAmount = baseUnit * files.length;
                        for (var item = 0; item < filelist.length; item++) {
                            const currentAmount = filelist[item].weight * baseTotal;
                            filelist[item].weight = currentAmount / (baseTotal + newlyAddBaseAmount)
                        }
                        // console.log('filelist', filelist.length, baseTotal, newlyAddBaseAmount)
                        for (var i = 0; i < files.length; i++) {
                            const fileName = files[i].name;
                            const uniqueTime = new Date().getTime();
                            let newID = `${layertId}-trait_${uniqueTime}-${i}`
                            filelist.push({
                                id: newID,
                                name: splitNameImage(fileName || ''),
                                image: URL.createObjectURL(files[i]),
                                weight: baseUnit / (baseTotal + newlyAddBaseAmount)
                            })
                        }
                        onUpdateImages(layer[index].id, filelist)
                        if (uploadRef && uploadRef.current && uploadRef.current.value) {
                            uploadRef.current.value = '';
                        }
                    }
                }}
            />
        </TraitItemStyle>
    )
}
export default TraitItem
interface ITraitItemStyle {
    type: 'item' | 'add'
}
const TraitItemStyle = memo(styled.div`
    .tis{
        border:solid 2px transparent;
        border-radius:10px;
        .tis_item{
            .tis_img{
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                border:${({ type }: ITraitItemStyle) => type === 'add' ? 'dashed' : 'solid'} 1px #c7c7c7;
                color:#c7c7c7;
                background:${({ type }: ITraitItemStyle) => type === 'add' ? 'transparent' : '#f3f4f6'};
                overflow: hidden;
                ${({ type }: ITraitItemStyle) => type === 'add' && css`
                    width:100%;
                    height:0;
                    position:relative;
                    padding-bottom:100%;
                `}
                svg{
                    position: absolute;
                    top:50%;
                    left:50%;
                    transform: translate(-50%,-50%);
                }
            }
            p{
                font-size:12px;
                margin-top:10px;
                display: inline-block;
                max-width:100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
                width: 100%;
                ${({ type }: ITraitItemStyle) => type === 'add' && css`
                    color:#c7c7c7;
                `}
            }
        }
        &.active{
            .tis_item{
                .tis_img{
                    ${({ type }: ITraitItemStyle) => type === 'item' && css`
                        border-width: 2px;
                        border-color:#2F9C95;
                    `}
                }
            }
        }
        img{
            max-height: 100%;
        }
    }
    .tis_file{
        display:none;
    }
`)
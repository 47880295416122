import { ILayer, IPrototype, IPrototypeItem, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { Fragment, memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { MdAdd, MdOutlineSaveAlt, MdOutlineSave, MdSave, MdInfoOutline } from 'react-icons/md'
import PrototypePreview from "./PrototypePreview"
import JSZip from "jszip"
import { saveAs } from 'file-saver';
import ToggleInclusive from "./toggleInclusive"
import ToggleBtn from "./toggleBtn"
const Prototype = () => {
    const { state: { prototypes, layer }, onAddPrototype, onChangePrototypeTitle, onUpdatePrototypes } = useContext(NFTGeneratorContext)
    const zip = new JSZip()

    const zipping = (zipData: any, metadata?: any) => {
        for (let i = 0; i < zipData.length; i++) {
            zip.file(`${i + 1}.png`, zipData[i]);
        }
        metadata && zip.file("metadata.json", metadata);
        zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, "collection.zip");
        });
    }
    return (
        <PrototypeStyle>
            <div className="cls_head">
                <div className="clsh_title">
                    <input value={prototypes?.active?.title || ''}
                        placeholder={prototypes ? `NFT #${prototypes.list.length}` : ''}
                        onChange={(e: any) => onChangePrototypeTitle(e.target.value)}
                    />
                </div>
                <div className="clsh_functional">
                    <div className="clshf" onClick={() => { onAddPrototype() }}>
                        <MdAdd /><span>New</span>
                    </div>
                    <div className="clshf" onClick={() => {
                        let prototypeList = { ...prototypes };
                        const indexedSaved = prototypeList.saved.findIndex((item: IPrototypeItem) => item.id === prototypeList.active?.id)
                        if (indexedSaved > -1) {
                            prototypeList.saved.splice(indexedSaved, 1);
                        } else {
                            prototypeList?.active && prototypeList.saved.push(prototypeList?.active);
                        }
                        onUpdatePrototypes(prototypeList)
                    }}>
                        {prototypes &&
                            <Fragment>
                                {prototypes.active &&
                                    prototypes.saved.findIndex((item: IPrototypeItem) => item.id === prototypes.active?.id) > -1 ?
                                    <Fragment>
                                        <MdSave size={18} /><span>Unsave</span>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <MdOutlineSave size={18} /><span>Save</span>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </div>
                    <div className="clshf download" onClick={() => {
                        // zipping()
                    }}>
                        <MdOutlineSaveAlt /><span>Download</span>
                    </div>
                    {prototypes.active &&
                        prototypes.saved.findIndex((item: IPrototypeItem) => item.id === prototypes.active?.id) > -1 &&
                        <div className="clshf inclusive">
                            <ToggleBtn id={prototypes.active?.id} />
                            <div data-tip="Include this prototype into collection?">
                                <MdInfoOutline size={16} />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="cls_body">
                <PrototypePreview />
            </div>
        </PrototypeStyle>
    )
}
export default Prototype

const PrototypeStyle = memo(styled.div`
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .cls_head{
        display:flex;
        justify-content: space-between;
        align-items: center;
        background:#E5F9E0;
        width:100%;
        .clsh_title{
            display: flex;
            align-items: center;
            width:40%;
            input{
                width:100%;
                position:relative;
                padding:10px;
                border-bottom:solid 2px transparent;
                background:transparent;
                &:focus{
                   border-color:#2F9C95;
                }
            }
        }
        .clsh_functional{
            display: flex;
            align-items: center;
            padding:10px;
            .clshf{
                display: flex;
                align-items: center;
                cursor:pointer;
                &:not(:first-child){
                    margin-left:15px;
                }
                span{
                    display: inline-block;
                    margin-left:5px;
                }
                &.download{
                    color:#c7c7c7;
                    cursor:not-allowed;
                }
                &.inclusive{
                    svg{
                        margin-left:5px;
                    }
                }
            }
        }
    }
    .cls_body{
        display: flex;
        justify-content: center;
        align-items: center;
        flex:1 1 0;
    }
`)
import { ILayer } from "contexts/NFTGeneratorContext"
import { memo } from "react"
import styled from "styled-components"
import TraitRarityGauge from "./traitRarityGauge"

interface ITraitInLayerItem {
    image: string,
    weight: number,
    layerId: string,
    id: string,
    name: string,
    layer: ILayer[],
    changeRarity: (layerId: string, imageId: String, newWeight: number, layer: ILayer[]) => void
}
const TraitInLayerItem = ({ image, weight, layerId, id, name, layer, changeRarity }: ITraitInLayerItem) => {
    return (
        <TraitInLayerItemStyle>
            <div className="tilis_image">
                <div>
                    <img src={image} alt="" />
                </div>
            </div>
            <div className="tilis_name">
                <p>{name}</p>
            </div>
            <div className="tilis_rarity">
                <TraitRarityGauge
                    imageId={id}
                    layerId={layerId}
                    weight={weight}
                    layer={layer}
                    changeRarity={changeRarity}
                />
            </div>
        </TraitInLayerItemStyle>
    )
}
export default TraitInLayerItem
const TraitInLayerItemStyle = memo(styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 30px;
    .tilis_image{
        flex-basis: 10%;
        >div{
            display: flex;
            width:40px;
            justify-content: center;
            border-radius: 10px;
            border: solid 1px #c7c7c7;
            color: #c7c7c7;
            background: #f3f4f6;
            overflow: hidden;
            img{
                width:50px;
            }
        }
    }
    .tilis_name{
        flex-basis: 25%;
    }
    .tilis_rarity{
        width:65%;
    }
`)
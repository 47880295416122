import { memo } from "react"
import styled from 'styled-components'
import loading from 'assets/images/loading.gif'
const Generating = () => {
    return (
        <GeneratingStyle>
            <img src={loading} alt="" />
        </GeneratingStyle>
    );
};

export default Generating;
const GeneratingStyle = memo(styled.div`
    width: 100vw;
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:2;
    position: fixed;
    top: 0;
    left: 0;
    background:#fff;
    img{
        max-width:30%;
    }
`)
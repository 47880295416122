import { NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { memo, useContext, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import CollectionDesc from "./collectionDesc"
import DimensionInput from "./dimensionInput"
import ExternalInput from "./externalInput"
import OutComePossibility from "./outcomePossibility"
import SupplyInput from "./SupplyInput"

const HeadFunctional = () => {
    const { state: { collection } } = useContext(NFTGeneratorContext)
    const [outcome, setOutcome] = useState(0)
    return (
        <HeadFunctionalStyle>
            <div className="hfs_left">
                <div className="hfsl_desc">
                    <CollectionDesc />
                </div>
                <div className="hfsl_size_supply">
                    <div className="hfsls">
                        <DimensionInput />
                        <SupplyInput />
                    </div>
                    <div className="hfsls_extenal">
                        <ExternalInput />
                        <OutComePossibility
                            outcome={outcome}
                            changeOutCome={setOutcome} />
                    </div>
                </div>
            </div>
            <div className={`hfs_right ${outcome >= collection.supply ? '' : 'inactive'}`}>
                <Link to="/generate">
                    <button>Generate collection</button>
                </Link>

            </div>

        </HeadFunctionalStyle>
    )
}
export default HeadFunctional
const HeadFunctionalStyle = memo(styled.div`
    display: flex;
    justify-content: space-between;
    .hfs_left{
        width:48.7vw;
        display: flex;
        .hfsl_desc{
            width:40%;
        }
        .hfsl_size_supply{
            width:60%;
            margin-left:20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .hfsls{
                display:flex;
                justify-content: space-between;
                width:100%;
                flex-wrap: wrap;
                >div{
                    &:first-child{
                        width:60%;
                        label{
                            min-width:70px;
                        }
                    }
                    &:nth-child(2){
                        width:40%;
                        padding-left:20px;
                    }
                    label{
                        display: inline-block;
                        font-size:12px;
                    }
                    input{
                        margin-left:10px;
                        max-width: 100px;
                        background: #f1f1f1;
                        border: solid 1px #f1f1f1;
                        border-radius: 5px;
                        padding:3px 5px;
                    }
                }
            }
            .hfsls_extenal{
                display: flex;
                >div{
                    &:first-child{
                        width:60%;
                        label{
                            display: inline-block;
                            font-size:12px;
                            min-width:70px;
                        }
                        input{
                            margin-left:10px;
                            background: #f1f1f1;
                            border: solid 1px #f1f1f1;
                            border-radius: 5px;
                            padding:3px 5px;
                        }
                    }
                    &:nth-child(2){
                        width:40%;
                        padding-left: 20px;
                    }
                }
            }
        }
    }
    .hfs_right{
        width:20.6vw;
        align-self: center;
        text-align: right;
        button{
            padding:10px 15px;
            min-width: 180px;
            max-width:100%;
            background:#2f9c95;
            border-radius:5px;
            color:#fff;
            &:hover{
                background:#40c9a2;
            }
        }
        &.inactive{
            a{
                pointer-events: none;
            }
            button{
                background: #c7c7c7;
                cursor: not-allowed;
            }
        }
    }
   
`)
import Testing from "conponents/testing"
import { memo } from "react"
import styled from "styled-components"
import LayerList from "./layerList"
import Prototype from "./prototype"
import SavedPrototype from "./savedPrototype"
import TraitList from "./traitsList"

const HomeComp = () => {
    return (
        <HomeCompStyle>
            <div className="hcs">
                <div className="hcs_layouts">
                    <LayerList />
                </div>
                <div className="hcs_traits">
                    <TraitList />
                </div>
                <div className="hcs_prototype">
                    <Prototype />
                </div>
                <div className="hcs_functioning">
                    <SavedPrototype/>
                </div>
            </div>
            {/* <Testing/> */}
        </HomeCompStyle>
    )
}
export default HomeComp
const HomeCompStyle = memo(styled.div`
    height:100%;
    .hcs{
        height:100%;
        display: flex;
        >div{
            height:100%;
            overflow-y: auto;
            overflow-x: hidden;
            :not(:last-child){
                border-right: solid 1px #c7c7c7;
            }
        }
        .hcs_layouts{
            flex-basis:10.7vw;
            background: #2f9c95;
            flex-wrap: wrap;
            overflow-x: visible;
        }
        .hcs_traits{
            flex-basis:20vw;
        }
        .hcs_prototype{
            flex-basis:48.7vw;
        }
        .hcs_functioning{
            flex-basis:20.6vw;
        }
    }
`)
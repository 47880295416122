import { NFTGeneratorContext, TDimen } from "contexts/NFTGeneratorContext"
import { useDebounce } from "helpers/hooks/useDebounce"
import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"


const SupplyInput = () => {
    const { state: { collection: { supply } }, onUpdateCollection } = useContext(NFTGeneratorContext)
    const [supplypool, setSupplyPool] = useState(supply)
    const supplyDebounce = useDebounce(supplypool, 300)
    useEffect(() => {
        if (supplyDebounce) {
            onUpdateCollection({ supply: supplyDebounce })
        }
    }, [supplyDebounce]);
    return (
        <SupplyInputStyle>
            <label htmlFor="collection_supply">Supply</label>
            <input id="collection_supply"
                value={supplypool}
                pattern="[0-9]*"
                onChange={(e: any) => {
                    if ((e.target.value.match(/^[0-9]*$/) !== null)) {
                        setSupplyPool(+e.target.value)
                    }
                }} />
        </SupplyInputStyle>
    )
}
export default SupplyInput
const SupplyInputStyle = memo(styled.div`

`)
import { NFTGeneratorContext } from "contexts/NFTGeneratorContext";
import { memo, useCallback, useContext, useEffect, useState, useTransition } from "react"
import styled from "styled-components"
import { MdOutlineSettings, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import TraitItem from "./traitItem";
import RarityLayout from "./rarityPanel";
import ClickOutSideComp from "conponents/core/ClickOutside";
import RarityPanel from "./rarityPanel";
interface ITraitSet {
    name: string;
    id: string;
    images: any[]
}
const TraitSet = ({ name, id, images }: ITraitSet) => {
    const { state: { layer, activeLayer }, onUpdateNameLayer } = useContext(NFTGeneratorContext)
    const [traitName, setTraitName] = useState(name)
    const [expand, setExpand] = useState(true)
    const [showRarityPanel, setShowRarityPanel] = useState(false)
    const [isPending, startTransition] = useTransition()
    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        startTransition(() => {
            setTraitName(e.target.value)
        })
    }, [traitName])
    useEffect(() => {
        if (!isPending) {
            onUpdateNameLayer(id, traitName)
        }
    }, [isPending, traitName])
    return (
        <TraitSetStyle>
            <div className="tss_head">
                <p>{name}</p>
                <div className="tssh">
                    <div className="tssh_edit" onClick={() => setShowRarityPanel(true)}>
                        <MdOutlineSettings size={18}/>
                    </div>
                    <div className="tssh_expander" onClick={() => setExpand(!expand)}>
                        {expand ? <MdKeyboardArrowUp size={18}/> : <MdKeyboardArrowDown size={18}/>}
                    </div>
                </div>
            </div>
            <div className="tss_body">
                <div className="tssb">
                    <div className="list_image">
                        {images.length > 0 && images.map((item: any, index: any) => {
                            return (
                                <div key={index} className="li_item">
                                    <TraitItem
                                        type="item"
                                        layertId={id}
                                        id={item.id}
                                        name={item.name}
                                        image={item.image}
                                        weight={item.weight}
                                    />
                                </div>
                            )
                        })}
                        <TraitItem type="add" layertId={id} />
                    </div>
                </div>
            </div>
            {showRarityPanel && <RarityPanel id={id} onClose={() => setShowRarityPanel(false)} />}
        </TraitSetStyle>
    )
}
export default TraitSet
const TraitSetStyle = memo(styled.div`
    .tss_head{
        padding:10px;
        display:flex;
        align-items: center;
        justify-content: space-between;
        background:#E5F9E0;
        .tssh{
            display: flex;
            align-items: center;
            .tssh_expander{
                margin-left:10px;
            }
        }
        p{
            font-size:14px;
        }
    }
    .tss_body{
        .tssb{
            padding:10px;
            .list_image{
                display:flex;
                align-items:center;
                gap:10px;
                flex-wrap:wrap;
                /* justify-content: space-between; */
                >div{
                    width:calc((100% - (10px * 3)) / 4);
                }
            }
        }
    }
`)
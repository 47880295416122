import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { MdOutlineLightbulb } from 'react-icons/md'
import { NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { useDebounce } from "helpers/hooks/useDebounce"
import Input from "conponents/core/Input"
import HeadFunctional from "./headFunctional"

const HeaderHome = () => {
    const { state: { collection }, onUpdateCollection, startNewCollection } = useContext(NFTGeneratorContext)
    const [colName, setColName] = useState(collection.name)

    const debouncedName = useDebounce(colName, 500);
    useEffect(() => {
        if (debouncedName) {
            onUpdateCollection({ name: debouncedName })
        }
    }, [debouncedName]);
    return (
        <HeaderHomeStyle>
            <div className="hhs_acc">
                <div className="hhsa_addnew" data-tip="Start blank collection">
                    <div className="hhsaa" onClick={() => startNewCollection()}>
                        <MdOutlineLightbulb size={40} />
                    </div>
                </div>
                <div className="hhsa_info">
                    <div className="hhsai_wallet">
                        <p>0x946...Cc9E</p>
                    </div>
                    <div className="hhsai_collection_name">
                        {/* <input value={colName} placeholder="Collection name" onChange={(e: any) => { setColName(e.target.value) }} /> */}
                        <Input value={colName} changeValue={setColName} />
                    </div>
                </div>
            </div>
            <div className="hhs_functional">
                <HeadFunctional />
            </div>
        </HeaderHomeStyle >
    )
}

export default HeaderHome
const HeaderHomeStyle = memo(styled.div`
    display: flex;
    align-items: stretch;
    height:100%;
    .hhs_acc{
        width:20vw;
        padding:10px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        .hhsa_addnew{
            width: 60px;
            height:100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background: #309c95;
            border-radius:5px;
            cursor:pointer;
            .hhsaa{

            }
        }
        .hhsa_info{
            margin-left:15px;
            flex: 1 1 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .hhsai_wallet{
                p{
                    font-size:12px;
                }
            }
            .hhsai_collection_name{
                /* input{
                    width:100%;
                    height:30px;
                    background:#f1f1f1;
                    border-radius:5px;
                    padding:3px 5px;
                    border:solid 1px #f1f1f1;
                    &.error{
                        border-color:#de0b00;
                    }
                } */
            }
        }
    }
    .hhs_functional{
        width:69.3vw;
        padding:10px;
    }
`)
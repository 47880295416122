import Generating from "conponents/Generating"
import { memo, useContext, useEffect } from "react"
import styled from "styled-components"
import JSZip from "jszip"
import { saveAs } from 'file-saver';
import { NFTGeneratorContext } from "contexts/NFTGeneratorContext";
import { renderCanvasImageWithBlob } from "helpers/renderCanvasImage";
import { writeFileSync } from 'fs';
import { Buffer } from "buffer";
import { createMetadata } from "helpers/generateMetadata";
interface IExportingCollection {
    onClose: () => void
}
const ExportingCollection = ({ onClose }: IExportingCollection) => {
    const { state: { collection, generated } } = useContext(NFTGeneratorContext)

    const zip = new JSZip();
    const zipping = (zipData: any, metadata: any) => {
        for (let i = 0; i < zipData.length; i++) {
            zip.folder(`${collection.name}/images`)?.file(`${i + 1}.png`, zipData[i]);
            zip.folder(`${collection.name}/metadata`)?.file(`${i + 1}.json`, metadata[i])
        }
        zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, `${collection.name}.zip`);
        }).then(() => { onClose() });
    }
    const urltoFile = (img: string) => {
        const data = img.replace(/^data:image\/\w+;base64,/, "");
        const buf = Buffer.from(data, 'base64');
        return buf
    }
    useEffect(() => {
        let imgList = [] as any[];
        let metaList = [] as any[];
        // console.log('generated', generated)
        Object.keys(generated.imageSet).forEach((item: any, index: any) => {
            let layerList = [] as string[];
            const temp = generated.imageSet[item];
            Object.keys(temp).forEach((element: any, index: number) => {
                layerList.push(temp[element])
            });
            const generatedImg = renderCanvasImageWithBlob({ nftImages: layerList, id: "render_export", size: collection.size })
            const metaData = createMetadata({
                type: "erc721",
                dataTrait: generated.metadata[index],
                name: collection.name,
                description: collection.description,
                compiler: "ez-nft.com"
            })
            const check = urltoFile(generatedImg)
            imgList.push(check)
            metaList.push(JSON.stringify(metaData))
        })
        zipping(imgList, metaList)
    }, [generated])
    return (
        <ExportingCollectionStyle>
            <canvas id="render_export" />
            <Generating />
        </ExportingCollectionStyle>
    )
}
export default ExportingCollection
const ExportingCollectionStyle = memo(styled.div`
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    z-index:3;
    background:#fff;
`)
import { NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import CollectionListItem from "./listItem"

interface IGCList {
    collection: any,
    page: number,
    pageSize: number,
    total: number,
    changePage: (newPage: number) => void,
}
const GCList = ({ collection, page, pageSize, total, changePage }: IGCList) => {
    const { state: { collection: { name } } } = useContext(NFTGeneratorContext)
    const [showItem, setShowItem] = useState({} as any)
    // console.log('collection', collection)
    useEffect(() => {
        let show = {} as any;
        for (let i = page * pageSize; i < (page * pageSize + pageSize); i++) {
            // console.log('collection[i]',i, collection[i])
            // console.log('ttat', Object.keys(collection)[i])
            if (Object.keys(collection)[i]) {
                show[Object.keys(collection)[i]] = collection[Object.keys(collection)[i]];
            }
        }
        setShowItem(show)
    }, [collection, page, pageSize, total])
    // console.log('showItem', showItem)
    return (
        <GCListStyle>
            <div className="gls_list">
                {Object.keys(showItem).map((item: any, i: number) => {
                    let layerList = [] as string[];
                    const temp = showItem[item];
                    // console.log('showItem[item]', temp, showItem[i])
                    Object.keys(temp).forEach((element: any, index: number) => {
                        layerList.push(temp[element])
                    });
                    // console.log('layerList', item, layerList)
                    //TODO name item theo ten da dat cho prototyppe lựa chọn từ trước
                    return (
                        <CollectionListItem
                            key={item}
                            nftImages={layerList}
                            id={item}
                            name={`${name} #${+item + 1}`}
                        />
                    )
                })}
            </div>
        </GCListStyle>
    )
}
export default GCList
const GCListStyle = memo(styled.div`
    .gls_list{
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        gap:30px;
        >div{
            width:calc((100% - (30px * 4)) / 5);
        }
    }
`)
import { memo } from "react"
import styled from "styled-components"

interface IChangePageSize {
    pageSize: number,
    changePageSize: (newPageSize: number) => void
}
const ChangePageSize = ({ pageSize, changePageSize }: IChangePageSize) => {
    return (
        <ChangePageSizeStyle>
            <label htmlFor="option_pageSize">Page size:</label>
            {/* <input list="options_recommend" id="option_pageSize"
                value={pageSize}
                pattern="[0-9]*"
                onChange={(e: any) => {
                    if ((e.target.value.match(/^[0-9]*$/) !== null)) {
                        changePageSize(+e.target.value)
                    }
                }}
            /> */}
            <select id="option_pageSize"
                value={pageSize}
                onChange={(e: any) => changePageSize(+e.target.value)}
            >
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>
        </ChangePageSizeStyle>
    )
}
export default ChangePageSize
const ChangePageSizeStyle = memo(styled.div`

`)
import { NFTGeneratorContext, TDimen } from "contexts/NFTGeneratorContext"
import { useDebounce } from "helpers/hooks/useDebounce"
import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"


const DimensionInput = () => {
    const { state: { collection: { size } }, onUpdateCollection } = useContext(NFTGeneratorContext)
    const [dimension, setDimesion] = useState(size)
    const dimensionDebounce = useDebounce(dimension, 300)
    useEffect(() => {
        if (dimensionDebounce) {
            onUpdateCollection({ size: dimensionDebounce })
        }
    }, [dimensionDebounce]);
    return (
        <DimensionInputStyle>
            <label htmlFor="collection_dimension">Dimension</label>
            <input id="collection_dimension"
                value={dimension[0]}
                onChange={(e: any) => {
                    let newDimen = [...dimension] as TDimen;
                    newDimen[0] = +e.target.value;
                    setDimesion(newDimen)
                }} />
            <input value={dimension[1]} onChange={(e: any) => {
                let newDimen = [...dimension] as TDimen;
                newDimen[1] = +e.target.value;
                setDimesion(newDimen)
            }} />
        </DimensionInputStyle>
    )
}
export default DimensionInput
const DimensionInputStyle = memo(styled.div`

`)
import { IPrototypeItem, ITraits, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { memo, useContext } from "react"
import styled from "styled-components"
import PropertiesItem from "./propertiesItem"

const SavedPrototype = () => {
    const { state: { prototypes, layer }, onUpdateActivePrototype } = useContext(NFTGeneratorContext)
    // prototypes?.saved && console.log('prototypes?.saved', prototypes?.saved)
    prototypes?.active && console.log(' prototypes?.active.traits',  prototypes?.active.traits)
    return (
        <SavedPrototypeStyle>
            <div className="sps_list">
                <div className="spsl_head">
                    <p>Saved prototypes</p>
                </div>
                <div className="spsl_body">
                    {prototypes?.saved && prototypes?.saved.map((item: IPrototypeItem) => {
                        return (
                            <div className="spsl" key={item.id} onClick={() => {
                                onUpdateActivePrototype(item)
                            }}>
                                <div className="spsl_img">
                                    <img src={item.imgUri} alt="" />
                                </div>
                                <p>{item.title}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="sps_properties">
                <div className="spsp_head">
                    <p>Traits</p>
                </div>
                <div className="spsp_body">
                    {prototypes?.active &&
                        layer.length > 0 &&
                        prototypes?.active.traits.map((item: ITraits) => {
                            return <PropertiesItem key={item.id}
                                id={item.id}
                                layerId={item.layer}
                            />
                        })}
                </div>
            </div>
        </SavedPrototypeStyle>
    )
}
export default SavedPrototype
const SavedPrototypeStyle = memo(styled.div`
    height:100%;
    .sps_list{
        height:40%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .spsl_head{
            padding: 10px;
            background-color: #E5F9E0;
        }
        .spsl_body{
            display:flex;
            align-items:center;
            gap:10px;
            flex-wrap:wrap;
            padding:10px;
            overflow-y:scroll;
            .spsl{
                width:calc((100% - (10px * 3)) / 4);
                text-align: center;
                font-size:12px;
                .spsl_img{
                    background:#f3f4f6;
                    border-radius:5px;
                }
                p{
                    margin-top:5px;
                }
            }
        }
    }
    .sps_properties{
        height:60%;
        overflow: hidden;
        .spsp_head{
            padding:10px;
            background: #E5F9E0;
        }
        .spsp_body{
            padding:10px;
        }
    }
`)
import { memo, useEffect, useState } from "react"
import styled from "styled-components"
import ChangePage from "./changePage"
import ChangePageSize from "./changePageSize"
interface IGCHeader {
    collection: any,
    page: number,
    pageSize: number,
    total: number,
    changePage: (newPage: number) => void,
    changePageSize: (newPageSize: number) => void
}
const GCHeader = ({ collection, page, pageSize, total, changePage, changePageSize }: IGCHeader) => {
    const [showRange, setShowRange] = useState({} as any)
    const calcRangeItem = (page: number, pageSize, collection) => {
        let show = {} as any;
        for (let i = page * pageSize; i < (page * pageSize + pageSize); i++) {
            if (collection[i]) {
                show[i] = collection[i];
            }
            else break;
        }
        return show
    }
    useEffect(() => {
        const range = calcRangeItem(page, pageSize, collection);
        setShowRange(range)
    }, [collection, page, pageSize, collection])
    // console.log('collection', Object.keys(collection))
    return (
        <GCHeaderStyle>
            <div className="gcgs_left">
                <span>{`Showing ${page * pageSize + 1} to ${(Object.keys(collection).length > pageSize ?
                        page * pageSize :
                        Object.keys(collection).length) + (Object.keys(showRange).length)
                    } of ${total} NFTs`}</span>
            </div>
            <div className="gcgs_right">
                <div className="gcgsr_pageSize">
                    <ChangePageSize
                        pageSize={pageSize}
                        changePageSize={changePageSize}
                    />
                </div>
                <div className="gcgsr_page">
                    <ChangePage
                        page={page}
                        changePage={changePage}
                    />
                </div>
            </div>
        </GCHeaderStyle>
    )
}
export default GCHeader
const GCHeaderStyle = memo(styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    .gcgs_left{

    }
    .gcgs_right{
        display: flex;
        align-items: center;
        .gcgsr_pageSize{
            margin-right:20px;
        }
        .gcgsr_pageSize{
            margin-left:1rem;
        }
    }
`)
import Generating from "conponents/Generating";
import { NFTGeneratorContext, ILayer } from "contexts/NFTGeneratorContext";
import { Fragment, memo, useContext, useEffect, useReducer } from "react"
import styled from "styled-components"
import GenerateCollection from "./collection";
import Functional from "./functional";
import { generateNFTs } from "./GenerateNFTs";
import OptionLayers from "./options/layers";
import OptionFilter from './options/filter'
import TabTraits from "./options/layers";
import PreviewTraits from "./preview/traits";
export type TListItem = 'traits' | 'filter' | 'edit' | string;
export interface IFunctionalState {
    activeTab: string,
    generating: boolean,
    generatedCollection: any,
    filter: {
        selected: string[],
        outcomeFilter: any
    }
}
export type TFunctionalAction =
    { type: "changeTab", tab: TListItem }
    | { type: "updateGenerating", generating: boolean }
    | { type: "updateGeneratedCollection", collection: any }
    | { type: "updateFilterSelection", id: string }
    | { type: "resetFilter" }
export interface IFilter {
    selected: string[],
    outcomeFilter: any
}
const initFilter = {
    selected: [],
    outcomeFilter: {}
} as IFilter;
const Generate = () => {
    const { state: { layer, collection, generated, prototypes }, onUpdateGenerated } = useContext(NFTGeneratorContext)
    const initState = {
        activeTab: 'traits',
        generating: true,
        generatedCollection: generated,
        filter: initFilter
    }
    const reducer = (state: IFunctionalState, action: TFunctionalAction) => {
        switch (action.type) {
            case 'changeTab': {
                const { tab } = action;
                return { ...state, activeTab: tab }
            }
            case 'updateGenerating': {
                const { generating } = action;
                return { ...state, generating }
            }
            case 'updateGeneratedCollection': {
                const { collection } = action;
                return {
                    ...state,
                    generatedCollection: collection,
                    generating: false
                }
            }
            case 'updateFilterSelection': {
                const { id } = action;
                let tempArr = [...state.filter.selected],
                    temGenerated = { ...state.generatedCollection };
                const idx = tempArr.findIndex((item: string) => item === id);
                if (idx > -1) { tempArr.splice(idx, 1) }
                else {
                    tempArr.push(id)
                }
                Object.keys(temGenerated).forEach((item: any) => {
                    let fullfilled = false;
                    Object.keys(temGenerated[item]).forEach((j: any) => {
                        for (let i = 0; i < tempArr.length; i++) {
                            if (tempArr[i] === j) {
                                fullfilled = true;
                                break;
                            }
                        }
                    })
                    // console.log('item', temGenerated[item])
                    if (!fullfilled) {
                        delete temGenerated[item]
                    }
                })
                console.log('temGenerated', temGenerated)
                return {
                    ...state,
                    filter: {
                        selected: tempArr,
                        outcomeFilter: temGenerated
                    }
                }
            }
            case 'resetFilter': {
                return {
                    ...state,
                    filter: initFilter
                }
            }
            default:
                return state
        }
    }
    const [state, dispatch] = useReducer(reducer, initState)
    const generatingCollection = (layer: ILayer[]) => {
        console.log('how many time')
        //TODO How can i improve loading state ?
        !state.generating && dispatch({ type: 'updateGenerating', generating: true });
        try {
            const { imageSet, metadata } = generateNFTs(collection.supply, layer, prototypes.saved)
            onUpdateGenerated(imageSet, metadata)
            dispatch({
                type: "updateGeneratedCollection",
                collection: imageSet
            })
        } catch (error) {
            dispatch({ type: 'updateGenerating', generating: false });
            console.log('generatingCollection ERROR', error)
        }
    }
    useEffect(() => {
        if (layer.length > 0) {
            generatingCollection(layer)
        }
    }, [])
    useEffect(() => {
        if (layer.length > 0) {
            generated.reload && generatingCollection(layer)
        }
    }, [generated.reload])
    return (
        <GenerateStyle>
            <div className="gs">
                {(state.generating || generated.reload) ?
                    <Generating />
                    :
                    <Fragment>
                        <div className="gs_functional">
                            <Functional
                                activeTab={state.activeTab}
                                changeTab={(tab: TListItem) => dispatch({ type: 'changeTab', tab })}
                            />
                        </div>
                        <div className="gs_options">
                            {state.activeTab === 'traits' && <OptionLayers />}
                            {state.activeTab === 'filter' && <OptionFilter
                                filter={state.filter}
                                updateFilter={(id: string) => {
                                    dispatch({ type: 'updateFilterSelection', id });
                                }}
                                onReset={() => {
                                    dispatch({ type: 'resetFilter' })
                                }}
                            />}
                        </div>
                        <div className="gs_main">
                            <GenerateCollection
                                isFilting={state.filter.selected.length > 0}
                                generating={state.generating}
                                collection={state.filter.selected.length > 0 ? state.filter.outcomeFilter : state.generatedCollection} />
                        </div>
                    </Fragment>
                }
            </div>
        </GenerateStyle>
    )
}
export default Generate
const GenerateStyle = memo(styled.div`
    height:100%;
    .gs{
        height:100%;
        display: flex;
        >div{
            height:100%;
            overflow-y: auto;
            overflow-x: hidden;
            :not(:last-child){
                border-right: solid 1px #c7c7c7;
            }
        }
        .gs_functional{
            flex-basis:10.7vw;
            background: #2f9c95;
            flex-wrap: wrap;
            overflow-x: visible;
        }
        .gs_options{
            flex-basis:20vw;
        }
        .gs_main{
            flex-basis:69.3vw;
            overflow-y: hidden;
        }
    }
`)
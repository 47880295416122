import { ILayer, ITraitImage, ITraits, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { MdDeleteOutline } from 'react-icons/md'

interface IPropertiesItem {
    id: string,
    layerId: string
}
const PropertiesItem = ({ id, layerId }: IPropertiesItem) => {
    const { state: { layer, prototypes }, onUpdateActivePrototype } = useContext(NFTGeneratorContext)
    const [idx, setIdx] = useState({
        layer: null as null | number,
        img: null as null | number
    })
    useEffect(() => {
        let indexedLayer = layer.findIndex((item: ILayer) => item.id === layerId);
        let indexedImage = null as null | number;
        if (indexedLayer > -1) {
            indexedImage = layer[indexedLayer].images.findIndex((item: ITraitImage) => item.id === id);
        }
        if (indexedLayer !== null &&
            indexedLayer > -1 &&
            indexedImage !== null &&
            indexedImage > -1
        ) setIdx({
            layer: indexedLayer,
            img: indexedImage
        })
    }, [id, layerId, layer])
    // console.log('idx', idx, layer, id, layerId)
    // idx.layer !== null && idx.img !== null && console.log('YYYY',idx.layer, idx.img, layer[idx.layer])
    return <PropertiesItemStyle>
        {idx.layer !== null && idx.img !== null && layer.length > 0 && <div className="pis">
            <div className="pis_img">
                <img src={layer[idx.layer].images[idx.img].image} alt="" />
            </div>
            <div className="pis_info">
                <div className="pisi">
                    <p>{layer[idx.layer].images[idx.img].name}</p>
                    <span>{layer[idx.layer].name}</span>
                </div>
                <div className="pisi_delete" onClick={() => {
                    if (prototypes.active) {
                        let newActive = { ...prototypes.active }
                        newActive.traits?.splice(newActive.traits?.findIndex((item: ITraits) => item.id === id), 1)
                        onUpdateActivePrototype(newActive)
                    }
                }}>
                    <MdDeleteOutline size={16} />
                </div>
            </div>
        </div>}
    </PropertiesItemStyle>
}

export default PropertiesItem
const PropertiesItemStyle = memo(styled.div`
    padding:10px;   
    .pis{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .pis_img{
            background:#f3f4f6;
            border-radius:4px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow:hidden;
            img{
                width:48px;
            }
        }
        
        .pis_info{
            flex:1 1 0;
            display:flex;
            align-items: center;
            justify-content: space-between;
            margin-left:10px;
            .pisi{
                p{
                    font-size:14px;
                    
                }
                span{
                    font-size:12px;
                    font-weight:bold;
                }
            }
            .pisi_delete{
                width:30px;
                height:30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background:#309c95;
                color:#fff;
                cursor:pointer;
            }
        }
    }
`)
import { NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { memo, useContext } from "react"
import styled from "styled-components"
import TraitItem from "./traitItem"
import TraitSet from "./traitSet"

const TraitList = () => {
    const { state: { layer } } = useContext(NFTGeneratorContext)
    return (
        <TraitListStyle>
            <div className="tls_search">
            </div>
            <div className="tls_content">
                {layer.map((item: any, index: number) => {
                    return <TraitSet
                        key={index}
                        name={item.name}
                        id={item.id}
                        images={item.images}
                    />
                })}
            </div>
        </TraitListStyle>
    )
}
export default TraitList
const TraitListStyle = memo(styled.div`
    .tls_content{
        >div{
            &:not(:first-child){
                border-top:solid 1px #c7c7c7;
            }
        }
    }
`)
import { memo, useContext } from "react"
import styled from "styled-components"
import { motion } from "framer-motion";
import { NFTGeneratorContext } from "contexts/NFTGeneratorContext";
interface IToggleBtn {
    id: string
}
const ToggleBtn = ({ id }: IToggleBtn) => {
    const { state: { prototypes }, onUpdateInclusive } = useContext(NFTGeneratorContext)
    console.log('prototypes', prototypes.active)
    return (
        <ToggleBtnStyle onClick={() => prototypes.active && onUpdateInclusive(prototypes.active?.id, !prototypes.active?.inclusive)}
            data-ison={prototypes.active?.inclusive}>
            <motion.div className="handle" layout transition={{
                type: "spring",
                stiffness: 700,
                damping: 30
            }} />
        </ToggleBtnStyle>
    )
}
export default ToggleBtn
const ToggleBtnStyle = memo(styled.div`
    width: 40px;
    height: 20px;
    background-color: #2f9c95;
    display: flex;
    justify-content: flex-start;
    border-radius: 20px;
    padding: 2.5px;
    cursor: pointer;
    &[data-ison="true"] {
        justify-content: flex-end;
    }
    .handle {
        width: 15px;
        height: 15px;
        background-color: white;
        border-radius: 50%;
    }
`)
import { memo, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useWidthScreen } from 'helpers/hooks/useWidthScreen'
import { MdArrowForward, MdArrowBack } from 'react-icons/md'

interface PaginationProps {
    currentPage: number;
    totalPage: number;
    setPage: any
}

const Pagination = ({ currentPage, totalPage, setPage }: PaginationProps) => {
    const { width } = useWidthScreen()
    const [destination, setDestination] = useState('')
    return (
        <PaginationWrap>
            {currentPage > 1 ?
                <Fragment>
                    <div className="button btn_prev" onClick={() => setPage(currentPage - 1)}>
                        <div className="prev"><MdArrowBack /></div>
                    </div>
                </Fragment>
                : ''
            }
            {width < 768 && currentPage === 3 &&
                <Fragment>
                    <div className="item" onClick={() => setPage(1)}>
                        <span>1</span>
                    </div>
                </Fragment>
            }
            {currentPage > 3 ?
                <Fragment>
                    <div className="item" onClick={() => setPage(1)}>
                        <span>1</span>
                    </div>
                    {width > 767 && currentPage !== 4 ?
                        <span className="item">...</span>
                        : ''
                    }
                </Fragment>
                : ''
            }
            {currentPage - 3 > -1 ?
                <Fragment>
                    {width > 767 && <Fragment>
                        <div className="item" onClick={() => setPage(currentPage - 2)}>
                            <span>{currentPage - 2}</span>
                        </div>
                    </Fragment>
                    }
                    {width < 768 && currentPage !== 3 ?
                        <span className="item">...</span>
                        : ''
                    }
                </Fragment>
                : ''
            }
            {currentPage - 2 > -1 ?
                <Fragment>
                    <div className="item" onClick={() => setPage(currentPage - 1)}>
                        <span>{currentPage - 1}</span>
                    </div>
                </Fragment>
                : ''
            }
            <div className="item active">
                <span>{currentPage}</span>
            </div>

            {totalPage - currentPage > 0 ?
                <Fragment>
                    <div className="item" onClick={() => setPage(currentPage + 1)}>
                        <span>{currentPage + 1}</span>
                    </div>
                </Fragment>
                : ''
            }
            {totalPage - currentPage > 1 ?
                <Fragment>
                    {width < 768 && currentPage !== (totalPage - 2) ?
                        <span className="item">...</span>
                        : ''
                    }
                    {width > 767 && <Fragment>
                        <div className="item" onClick={() => setPage(currentPage + 2)}>
                            <span>{currentPage + 2}</span>
                        </div>
                    </Fragment>}
                </Fragment>
                : ''
            }
            {totalPage - currentPage > 2 ?
                <Fragment>
                    {width > 767 && (currentPage < (totalPage - 2)) && (currentPage < totalPage - 3) ?
                        <span className="item">...</span>
                        : ''
                    }
                    <div className="item" onClick={() => setPage(totalPage)}>
                        <span>{totalPage}</span>
                    </div>

                </Fragment>
                : ''
            }
            {width < 768 && currentPage === totalPage - 2 &&
                <Fragment>
                    <div className="item" onClick={() => setPage(totalPage)}>
                        <span>{totalPage}</span>
                    </div>

                </Fragment>
            }
            {totalPage - currentPage > 0 ?
                <Fragment>
                    <div className="button" onClick={() => setPage(currentPage + 1)}>
                        <div className="next"><MdArrowForward /></div>
                    </div>
                </Fragment>
                : ''
            }

            {/* {totalPage > 10 &&
                <div className="goto">
                    <NumberFormat value={destination} displayType={'input'} thousandSeparator={true}
                        onValueChange={(e) => { setDestination(e.value) }}
                        placeholder={i18n.t('page')}
                        onKeyPress={event => {
                            if (event.key === 'Enter') { destination !== '' && setPage(+destination) }
                        }}
                    />
                    <div className="submit_goto" onClick={() => destination !== '' && setPage(+destination)}>
                        <img src={searchImg} alt="search" />
                    </div>
                </div>
            } */}
        </PaginationWrap>
    )
}
export default Pagination

const PaginationWrap = memo(styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    .item{
        &:not(:last-child){
            margin-right:1.1rem;
            @media (max-width:480px){
                margin-right:0.6rem;
            }
        }
    }
    .next{
        img{
            transform:rotate(180deg);
        }
    }
    a, div{
        cursor:pointer;
        span{
            font-size: 1rem;
            line-height: 1;
            @media (max-width:480px){
                font-size:0.8rem
            }
        }
        &:hover{
            span{
            }
        }
    }
    div.active{
        span{
            font-size: 1.2rem;
            line-height: 1;
            font-weight:700;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .button{
        display:flex;
        align-items:center;
        margin:0 1.1rem 0 0;
        @media (max-width:480px){
            margin:0 0.6rem 0 0;
        }
        &>div{
            align-items: center;
            justify-content: center;
            display: flex;
            img{
                height:0.714rem;
                &.inactive{
                    display:block;
                }
                &.active{
                    display:none;
                }
            }
            &.prev{
                img{
                    margin-right:0.42rem;
                }
            }
            &.next{
                img{
                    margin-left:0.42rem;
                }
            }
        }
        .next_text{
            padding-right:0.43rem;
        }
        .prev_text{
            padding-left:0.43rem;
        }
        &:hover{
            img{
                &.inactive{
                    display:none;
                }
                &.active{
                    display:block;
                }
            }
            span{
            }
        }
    }
    .goto{
        display: flex;
        align-items:center;
        height:40px;
        border-radius:8px;
        @media (max-width:480px){
            height:30px;
        }
        input{
            padding-left:0.9rem;
            width:70px;
            border:none;
            @media (max-width:480px){
                padding-left:0.4rem;
                width:50px;
            }
        }
        .submit_goto{
            height:100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left:0.5rem;
            padding-right:0.9rem;
            flex:0 0 30px;
            @media (max-width:480px){
                padding-right:0.4rem;
                flex: 0 0 20px;
            }
            img{
                height:20px;
                width:20px;
                @media (max-width:480px){
                    height:15px;
                    width:15px;
                    
                }
            }
        }
    }
`)
import { ILayer, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { useDebounce } from "helpers/hooks/useDebounce"
import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"
interface ILayoutTitle {
    id: string
}
const LayoutTitle = ({ id }: ILayoutTitle) => {
    const { state: { layer }, onUpdateNameLayer } = useContext(NFTGeneratorContext)
    const [title, setTitle] = useState(layer[layer.findIndex((item: ILayer) => item.id === id)].name)
    const debouncedTitle = useDebounce(title, 100);
    useEffect(() => {
        if (debouncedTitle) {
            onUpdateNameLayer(id, title)
        }
    }, [debouncedTitle]);
    return (
        <LayoutTitleStyle>
            <input value={title} onChange={(e) => setTitle(e.target.value)} />
        </LayoutTitleStyle>
    )
}
export default LayoutTitle
const LayoutTitleStyle = memo(styled.div`

`)
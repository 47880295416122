import { useEffect } from "react";
import LoadingScreen from "../LoadingScreen";
import { getWithExpiry, setWithExpiry } from "./storage";
interface ErrorFallbackProps {
    error: any
}
const ErrorFallback = ({ error }: ErrorFallbackProps) => {
    // Handles failed lazy loading of a JS/CSS chunk.
    useEffect(() => {
        const chunkFailedMessage = /X Loading chunk [\d]+ failed/;
        if (error?.message && chunkFailedMessage.test(error.message)) {
            console.log('chunkFailedMessage', error?.message)
            if (!getWithExpiry("chunk_failed")) {
                setWithExpiry("chunk_failed", "true", 10000);
                window.location.reload();
            }
        }
    }, [error]);
    return (
        <LoadingScreen />
    );
}

export default ErrorFallback
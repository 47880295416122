import { memo, useState } from "react"
import styled from "styled-components"
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md'
interface IFilterItem {
    name: string,
    id: string,
    active: boolean,
    updateFilter: (selected: string) => void;
}
const FilterItem = ({ name, id, active, updateFilter }: IFilterItem) => {
    return (
        <FilterItemStyle onClick={() => {
            updateFilter(id)
        }}>
            {active ?
                <MdCheckBox />
                :
                <MdCheckBoxOutlineBlank />
            }
            <span>{name}</span>
        </FilterItemStyle>
    )
}
export default FilterItem
const FilterItemStyle = memo(styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    span{
        padding-left:5px;
    }
`)
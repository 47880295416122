import RarityPanel from "conponents/home/traitsList/rarityPanel"
import { ILayer, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { memo, useContext, useState } from "react"
import styled from "styled-components"
import LayerItem from "./layerItem"

const OptionTraits = () => {
    const { state: { layer } } = useContext(NFTGeneratorContext)
    const [rarityPanel, setRarityPanel] = useState('')
    return (
        <OptionTraitsStyle>
            <div className="ots_title">
                <p>Layers & Traits</p>
            </div>
            <div className="ots_content">
                {layer.map((item: ILayer, index: number) => {
                    return (
                        <LayerItem key={item.id}
                            id={item.id}
                            images={item.images}
                            name={item.name}
                            weight={item.weight}
                            setRarityPanel={setRarityPanel}
                        />
                    )
                })}
                {rarityPanel !== '' && <RarityPanel id={rarityPanel} onClose={() => setRarityPanel('')} />}
            </div>

        </OptionTraitsStyle>
    )
}
export default OptionTraits
const OptionTraitsStyle = memo(styled.div`
    .ots_title{
        padding:20px 10px;
        p{
            font-size:1.1rem;
        }
    }
    .ots_content{
        border-top:solid 1px #c7c7c7;
        >div{
            &:not(:last-child){
                border-bottom:solid 1px #c7c7c7;
            }
        }
    }
`)
import { memo, useState } from "react"
import styled from "styled-components"
import { MdArrowForward } from 'react-icons/md'
import Input from "conponents/core/Input"
interface IChangePage {
    page: number,
    changePage: (newPage: number) => void,
}
const ChangePage = ({ page, changePage }: IChangePage) => {
    const [newPage, setNewPage] = useState('')
    return (
        <ChangePageStyle>
            <div className="cps_input">
                <label htmlFor="current_page">Page :</label>
                <div className="cpsi">
                    <Input id="current_page"
                        value={newPage + ''}
                        placeholder={page + 1 + ''}
                        changeValue={(dataString: string) => setNewPage(dataString)}
                    />
                </div>
            </div>
            <div className="cps_btn"
                onClick={() => { changePage(+newPage) }}>
                <MdArrowForward />
            </div>
        </ChangePageStyle>
    )
}
export default ChangePage
const ChangePageStyle = memo(styled.div`
    display: flex;
    align-items: stretch;
    .cps_input{
        display: flex;
        align-items: center;
        label{
            margin-right:5px;
        }
        .cpsi{
            width:50px;
        }
    }
    .cps_btn{
        width: 30px;
        height:30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left:10px;
        background:#2f9c95;
        border-radius: 5px;;
        color:#fff;
        cursor:pointer;
        &:hover{
            background:#40c9a2;
        }
    }
`)
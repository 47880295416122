import { ILayer, ITraitImage } from "contexts/NFTGeneratorContext"
import { memo, useState } from "react"
import styled from "styled-components"
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import FilterItem from "./filterItem";
import { IFilter } from "conponents/generate";
interface IFilterLayer {
    id: string,
    name: string,
    images: ITraitImage[],
    weight: number,
    filter: IFilter,
    updateFilter: (selected: string) => void;
}
const FilterLayer = ({ name, id, images, weight, filter, updateFilter }: IFilterLayer) => {
    const [expand, setExpand] = useState(true)
    return (
        <FilterLayerStyle>
            <div className="fls_head">
                <p>{name}</p>
                <div className="flsh">
                    {expand ? <MdExpandLess /> : <MdExpandMore />}
                </div>
            </div>
            <div className="fls_content">
                {images.map((item: ITraitImage) => {
                    return (
                        <FilterItem
                            key={item.id}
                            name={item.name}
                            id={item.id}
                            active={filter.selected.findIndex((i: string) => i === item.id) > -1}
                            updateFilter={updateFilter}
                        />
                    )
                })}
            </div>
        </FilterLayerStyle>
    )
}
export default FilterLayer
const FilterLayerStyle = memo(styled.div`
    .fls_head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:20px 10px;
    }
    .fls_content{
        padding:0 10px 20px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap:10px;
        flex-wrap:wrap;
        >div{
            width:calc(50% - 5px);
        }
    }
`)
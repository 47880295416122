import Generate from "conponents/generate"
import { memo } from "react"
import styled from "styled-components"


const Generator = () => {
    return (
        <GeneratorStyle>
            <Generate />
        </GeneratorStyle>
    )
}
export default Generator
const GeneratorStyle = memo(styled.div`

`)
import BigNumber from "bignumber.js"
import { ILayer, ITraitImage, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { useDebounce } from "helpers/hooks/useDebounce"
import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import TraitInLayerItem from "./traitInLayerItem"
interface ITraitInLayer {
    id: string
}
const TraitInLayer = ({ id }: ITraitInLayer) => {
    const { state: { layer }, onUpdateImageRarityInLayer } = useContext(NFTGeneratorContext)
    const [layerState, setLayerState] = useState(layer)
    const debouncedLayer = useDebounce(layerState, 500);
    useEffect(() => {
        if (debouncedLayer) {
            onUpdateImageRarityInLayer(debouncedLayer)
        }
    }, [debouncedLayer]);
    const changeRarity = (layerId: string, imageId: String, newWeight: number, layer: ILayer[]) => {
        const layerIdx = [...layer].findIndex((item: ILayer) => item.id === layerId);
        let layerUpdated = [...layer];
        if (layerIdx > -1) {
            let layerImages = [...layer][layerIdx].images;
            const imageIdx = layerImages.findIndex((item: ITraitImage) => item.id === imageId);
            let tempSplice = [...layerImages];
            tempSplice.splice(imageIdx, 1);
            const noneZeroRarity = tempSplice.filter((item) => {
                // console.log('item.weight > 0', item.weight > 0)
                return item.weight > 0
            })
            console.log('alreadyZero', noneZeroRarity)
            const modifiedVal =
                +new BigNumber(layerImages[imageIdx].weight - newWeight).dividedBy(noneZeroRarity.length);
                // (layerImages[imageIdx].weight * 100 * layerImages.length) - (newWeight * 100 * layerImages.length);
                // +new BigNumber(layerImages[imageIdx].weight).multipliedBy(100).multipliedBy(layerImages.length) -
                // +new BigNumber(newWeight).multipliedBy(100).multipliedBy(noneZeroRarity.length);
            // console.log('%c LLLLLLLLLL', 'color:orange', 'modifiedVal: ' + modifiedVal, '  --- newWeight: ' + newWeight)
            for (let i = 0; i < layerImages.length; i++) {
                if (i === imageIdx) {
                    layerImages[i].weight = newWeight;
                } else {
                    const itemWeight = layerImages[i].weight;
                    if (itemWeight > 0) {
                        // console.log('%c XXXXXXXXX', 'color:red', itemWeight, layerImages[i].name)
                        const data = itemWeight + modifiedVal;
                        // const data = ((itemWeight * (layerImages.length * 100)) + (modifiedVal / (layerImages.length - 1))) / (layerImages.length * 100);
                        // console.log('%c YYYYYY', 'color:cyan', 'data', +data)
                        layerImages[i].weight = data < 0 ? 0 : data;
                    }
                }
            }
            layerUpdated[layerIdx].images = layerImages;
            setLayerState(layerUpdated)
        }
    }
    return (
        <TraitInLayerStyle>
            {layerState[layerState.findIndex((item: ILayer) => item.id === id)].images.map((item: ITraitImage, index: number) => {
                return <TraitInLayerItem
                    key={`${item.id}_${index}`}
                    image={item.image}
                    weight={item.weight}
                    layerId={id}
                    id={item.id}
                    name={item.name}
                    layer={layerState}
                    changeRarity={changeRarity}
                />
            })}
        </TraitInLayerStyle>
    )
}
export default TraitInLayer

const TraitInLayerStyle = memo(styled.div`
    >div{
        &:not(:last-child){
            margin-bottom:20px;
        }
    }
`)
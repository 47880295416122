import { ILayer, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"
interface IOutComePossibility {
    outcome: number,
    changeOutCome: (newOutcome: number) => void
}
const OutComePossibility = ({ outcome, changeOutCome }: IOutComePossibility) => {
    const { state: { collection, layer } } = useContext(NFTGeneratorContext)
    const calcOutcome = (layer: ILayer[]) => {
        let baseOutcome = 1,
            underCertainOutcome = 0,
            arrUnder = [] as ILayer[];
        if (layer.length > 1) {
            for (let i = 0; i < layer.length; i++) {
                if (layer[i].weight > 0 && layer[i].images.length > 0) {
                    if (layer[i].weight < 1) {
                        arrUnder.push(layer[i])
                    }
                    baseOutcome = baseOutcome * layer[i].images.length;
                    // console.log('baseOutcome', baseOutcome)
                }
            }
            if (arrUnder.length > 0) {
                for (let j = 0; j < arrUnder.length; j++) {
                    underCertainOutcome = underCertainOutcome + (baseOutcome / arrUnder[j].images.length);
                }
            }
            if (layer.findIndex((item: ILayer) => item.weight === 1) === -1) baseOutcome = baseOutcome + 1;
        } else {
            if (layer.length === 1) {
                baseOutcome = 1;
            } else {
                baseOutcome = 0;
            }
        }
        // console.log('XXX', baseOutcome, underCertainOutcome, layer)
        changeOutCome(baseOutcome + underCertainOutcome)
    }
    useEffect(() => {
        calcOutcome(layer)
    }, [layer])
    return (
        <OutComePossibilityStyle>
            Outcome: {outcome} / {collection.supply}
        </OutComePossibilityStyle>
    )
}
export default OutComePossibility
const OutComePossibilityStyle = memo(styled.div`
    font-size:12px;
`)
import { ILayer, IPrototypeItem, ITraitImage, ITraits, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { renderCanvasImage } from "helpers/renderCanvasImage"
import { memo, useContext, useEffect } from "react"
import styled from "styled-components"


const PrototypePreview = () => {
    //TODO bug prototype default đã chọn hình nhưng chưa lưu, create new prototype mới, chọn hình và lưu thì bị sinh ra 2 saved prototypes
    const { state: { prototypes, layer, collection }, onUpdatePrototypes } = useContext(NFTGeneratorContext)
    useEffect(() => {
        if (prototypes?.active && layer.length > 0) {
            const combinedImgUri = renderCanvasImage({
                imageSet: prototypes.active.traits,
                layer,
                id: "canvasFrame",
                size: collection.size
            })
            let tempList = prototypes.list,
                tempActive = prototypes.active,
                tempSaved = prototypes.saved;
            const indexedList = tempList.findIndex((item: IPrototypeItem) => item.id === tempActive.id);
            const indexedSaved = tempSaved.findIndex((item: any) => item.id === tempActive.id);
            tempActive.imgUri = combinedImgUri;
            indexedList > -1 && tempList.splice(indexedList, 1, tempActive);
            indexedSaved > -1 && tempSaved.splice(indexedList, 1, tempActive);
            onUpdatePrototypes({
                active: tempActive,
                list: tempList,
                saved: tempSaved
            })
        }
        else {
            let canvas = document.getElementById("canvasFrame");
            const context = (canvas as any)?.getContext('2d');
            context.clearRect(0, 0, (canvas as any)?.width, (canvas as any)?.height);
        }
    }, [prototypes?.active, prototypes?.saved, layer])
    return (
        <PrototypePreviewStyle>
            <canvas id="canvasFrame" />
        </PrototypePreviewStyle>
    )
}
export default PrototypePreview
const PrototypePreviewStyle = memo(styled.div`
    border: solid 1px #f5f5f5;
    border-radius: 5px;
`)
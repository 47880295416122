import InputUploadTest from 'conponents/testing/InputUploadTest'
import { ILayer, NFTGeneratorContext } from 'contexts/NFTGeneratorContext'
import React, { memo, useCallback, useContext, useEffect, useRef, useState, useTransition } from 'react'
import styled from 'styled-components'
import { MdClear, MdOutlineModeEditOutline } from 'react-icons/md'
import ClickOutSideComp from 'conponents/core/ClickOutside'
interface ILayerListItem {
    name: string,
    id: string,
    active: boolean;
    handleClick: () => void
}
const LayerListItem = ({ name, id, active, handleClick }: ILayerListItem) => {
    const { state: { layer },
        onUpdateNameLayer,
        onRemoveLayer,
        onUpdateActiveLayout
    } = useContext(NFTGeneratorContext)
    const inputRef = useRef(null as any)
    const [isEdit, setIsEdit] = useState(true)
    const [isPending, startTransition] = useTransition()
    const [nameMeta, setNameMeta] = useState(name)
    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        startTransition(() => {
            setNameMeta(e.target.value)
        })
    }, [nameMeta])
    useEffect(() => {
        if (!isPending) {
            onUpdateNameLayer(id, nameMeta)
        }
    }, [isPending, nameMeta])
    useEffect(() => {
        !!isEdit && inputRef?.current?.focus()
    }, [isEdit])
    useEffect(() => {
        setNameMeta(layer[layer.findIndex((item: ILayer) => item.id === id)].name)
    }, [layer[layer.findIndex((item: ILayer) => item.id === id)].name])
    return (
        <LayerListItemStyle active={active} onClick={handleClick}>
            <div className='llis' onClick={() => onUpdateActiveLayout(id)} id={id}>
                {isEdit ?
                    <ClickOutSideComp handleClickOutSide={() => { setIsEdit(false) }}>
                        <input
                            ref={inputRef}
                            value={nameMeta}
                            onChange={onChange}
                            placeholder="Layer's name"

                        />
                    </ClickOutSideComp>
                    : <span>{nameMeta}</span>
                }
                {!isEdit && <div className="llis_edit" onClick={() => { setIsEdit(true) }}>
                    <MdOutlineModeEditOutline size={12} />
                </div>}

            </div>
            <div className='llis_remove'>
                <MdClear onClick={() => onRemoveLayer(id)} />
            </div>
        </LayerListItemStyle>
    )
}

export default LayerListItem
interface ILayerListItemStyle {
    active: boolean;
}
const LayerListItemStyle = memo(styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position:relative;
    padding:10px 10px 10px 30px;
    cursor: pointer;
    .llis{
        position: relative;
        display:flex;
        align-items: center;
        flex-wrap: wrap;
        min-height:20px;
        width:100%;
        input{
            font-size:13px;
            width:100%;
            color:#fff;
            text-align:center;
            background:transparent;
            &::placeholder{
                color:${({ active }: ILayerListItemStyle) => active ? '#bbbbbb' : '#bbbbbb'};
            }
        }
        span{
            font-size:13px;
            width:100%;
            text-align:center;
            cursor: pointer;
            color:#fff;
        }
        .llis_edit{
            display:none;
            position: absolute;
            top:50%;
            left:5px;
            width:20px;
            height:20px;
            background:#fff;
            border:solid 1px #c7c7c7;
            border-radius:50%;
            transform: translateY(-50%);
            text-align: center;
            cursor:pointer;
        }
    }
   .llis_remove{
        display:none;
        position:absolute;
        width:10px;
        height:10px;
        top:0;
        right:0;
        svg{
            width:10px;
            height:10px;
            position:absolute;
            top:2px;
            right:0px;
            color:#fff;
            cursor: pointer;
        }
        &:before{
            content:'';
            position:absolute;
            top:-2px;
            right:-10px;
            border-color: transparent transparent #40c9a2 transparent;
            border-style: solid;
            border-width: 0px 15px 15px 15px;
            height: 0px;
            width: 0px;
            transform:rotate(45deg);
        }
    }
    &:hover{
        .llis_remove{
            display:flex;
        }
        .llis{
            .llis_edit{
                display:block;
            }
        }
    }
`)
import { memo } from "react"
import styled from "styled-components"
import { TListItem } from "..";

const listFunc = ['traits', 'filter', 'edit'];
interface IFunctional {
    activeTab: TListItem;
    changeTab: (tab: TListItem) => void
}
const Functional = ({ activeTab, changeTab }: IFunctional) => {
    return (
        <FunctionalStyle>
            {listFunc.map((item: TListItem, index: number) => {
                return <div key={index}
                    className={`fs ${activeTab === item ? 'active' : ''}`}
                    onClick={() => changeTab(item)}
                >
                    <span>{item}</span>
                </div>
            })}
        </FunctionalStyle>
    )
}

export default Functional
const FunctionalStyle = memo(styled.div`
    &:not(:last-child){
        border-bottom:solid 1px #c7c7c7c7;
    }
    .fs{
        display:flex;
        align-items: stretch;
        justify-content: space-between;
        position:relative;
        cursor: pointer;
        color:#fff;
        text-align:center;
        padding:10px;
        flex-direction: column;
        align-items: center;
        border-bottom:solid 1px #c7c7c7;
        &:not(.active){
            &:hover{
                background:#40c9a2;
            }
        }
        &.active{
            background:#664147;
            .llsli{
            }
        }
        span{
            text-transform:capitalize;
        }
    }
`)
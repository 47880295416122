import { ITraitImage } from "contexts/NFTGeneratorContext"
import { memo } from "react"
import styled from "styled-components"
import { MdOutlineSettings } from 'react-icons/md'
interface ILayerItem {
    id: string,
    images: ITraitImage[],
    name: string,
    weight: number,
    setRarityPanel: (panel: string) => void
}
const LayerItem = ({ id, images, name, weight, setRarityPanel }: ILayerItem) => {
    return (
        <LayerItemStyle>
            <div className="lis_left">
                <div className="lisl_img">
                    <img src={images[0].image} alt="" />
                </div>
                <div className="lisl_info">
                    <p>{name}</p>
                    <span>{images.length} {images.length > 1 ? 'traits' : 'trait'}</span>
                </div>
            </div>
            <div className="lis_right">
                <div className="lisr_btn" onClick={()=>setRarityPanel(id)}>
                    <button><MdOutlineSettings size={18} /> Edit rarity</button>
                </div>
            </div>
        </LayerItemStyle>
    )
}
export default LayerItem
const LayerItemStyle = memo(styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px;
    .lis_left{
        display: flex;
        .lisl_img{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            border: solid 1px #c7c7c7;
            color: #c7c7c7;
            background: #f3f4f6;
            overflow: hidden;
            img{
                max-width:50px;
            }
        }
        .lisl_info{
            margin-left:10px;
            p{
                font-size:1rem;
                line-height: 25px;;
            }
            span{
                font-size:0.8rem;
                color:#919191;
                line-height: 25px;
            }
        }
    }
    .lis_right{
        .lisr_btn{
            padding:10px;
            border: solid 1px #c7c7c7;
            border-radius:5px;
            box-shadow: 2px 2px 6px 1px #ededed;
            button{
                display: flex;
                align-items: center;
                font-size: 0.8rem;
                svg{
                    margin-right: 5px;
                }
            }
        }
    }
`)
import React, { createContext, useCallback, useState } from 'react'
export const CollectionContext = createContext({
    collection: {
        name: '',
        description: "",
        size: 100,
        dimension: [500, 500]
    },
    onChangeCollection: ({ ...props }: ICollection) => { }
})
export interface ICollection {
    name: string,
    description: string,
    size: number,
    dimension: [number, number]
}
const CollectionContextWrap = ({ children }: any) => {
    const [collection, setCollection] = useState<ICollection>({
        name: '',
        description: "",
        size: 100,
        dimension: [500, 500]
    })
    const onChangeCollection = useCallback(({ ...props }: ICollection) => {
        setCollection({ ...collection, ...props })
    }, [collection])
    // console.log({ collection })
    return (
        <CollectionContext.Provider value={{
            collection,
            onChangeCollection
        }} >
            {children}
        </CollectionContext.Provider>
    )
}

export default CollectionContextWrap
import { IFilter } from "conponents/generate";
import { ILayer, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { memo, useContext } from "react"
import styled from "styled-components"
import FilterLayer from "./filterLayer"
interface IFilterCollection {
    filter: IFilter,
    updateFilter: (selected: string) => void;
    onReset: () => void
}
const FilterCollection = ({ filter, updateFilter, onReset }: IFilterCollection) => {
    const { state: { layer } } = useContext(NFTGeneratorContext)
    return (
        <FilterCollectionStyle>
            <div className="fcs_title">
                <p>Filter collection</p>
                <div className="fcst" onClick={onReset}>
                    <button>Reset</button>
                </div>
            </div>
            <div className="fcs_content">
                {layer.map((item: ILayer) => {
                    return (
                        <FilterLayer
                            key={item.id}
                            name={item.name}
                            id={item.id}
                            images={item.images}
                            weight={item.weight}
                            filter={filter}
                            updateFilter={updateFilter}
                        />
                    )
                })}
            </div>
        </FilterCollectionStyle>
    )
}
export default FilterCollection
const FilterCollectionStyle = memo(styled.div`
    .fcs_title{
        padding:20px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
            font-size:1.1rem;
        }
        button{
            padding:7px 12px;
            border-radius:5px;
            background:#c7c7c7;
            font-size:0.9rem;
        }
    }
    .fcs_content{
        border-top:solid 1px #c7c7c7;
        >div{
            border-bottom:solid 1px #c7c7c7;
        }
    }
`)
import { memo } from "react"
import styled from "styled-components"
import { MdClose } from 'react-icons/md'
import LayoutTitle from "./layoutTitle";
import ClickOutSideComp from "conponents/core/ClickOutside";
import RarityGauge from "./rarityGauge";
import TraitInLayer from "./traitInLayer";
interface IRarityPanel {
    id: string;
    onClose: () => void;
}
const RarityPanel = ({ id, onClose }: IRarityPanel) => {
    return (
        <RarityPanelStyle>
            <div className="rls">
                <ClickOutSideComp handleClickOutSide={onClose}>
                    <div className="rls_content">
                        <div className="rlsc_close" onClick={onClose}>
                            <MdClose size={40} />
                        </div>
                        <div className="rlsc">
                            <div className="rlsc_head">
                                <div className="rlsch_title">
                                    <LayoutTitle id={id} />
                                </div>
                                <div className="rlsch_rarity">
                                    <RarityGauge id={id} />
                                </div>
                            </div>
                            <div className="rlsc_body">
                                <TraitInLayer id={id} />
                            </div>
                        </div>
                    </div>
                </ClickOutSideComp>
            </div>
        </RarityPanelStyle>
    )
}
export default RarityPanel
const RarityPanelStyle = memo(styled.div`
    display:flex;
    justify-content: flex-end;
    width:100vw;
    height:100vh;
    position: fixed;
    top:0;
    left:0;
    background:rgb(0 0 0 / 60%);
    z-index:1;
    .rls{
        height:100%;
        width:69.3%;
        background: #fff;
        position:relative;
        >div{
            height:100%;
        }
        .rls_content{
            height: 100%;
            .rlsc_close{
                position: absolute;
                width: 50px;
                height: 50px;
                top: 0;
                left: -50px;
                color:#fff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .rlsc{
                height:100%;
                .rlsc_head{
                    display:flex;
                    align-items:center;
                    justify-content: space-between;
                    padding: 15px 20px;
                    border-bottom:solid 1px #c7c7c7;
                    gap:0 20px;
                    .rlsch_title{
                        
                    }
                    .rlsch_rarity{

                    }
                }
                .rlsc_body{
                    padding: 15px 20px;
                    overflow:auto;
                }
            }
        }
    }
`)
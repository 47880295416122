import { NFTGeneratorContext } from "contexts/NFTGeneratorContext";
import { useDebounce } from "helpers/hooks/useDebounce";
import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"

const CollectionDesc = () => {
    const { state: { collection: { description } }, onUpdateCollection } = useContext(NFTGeneratorContext)
    const [desc, setDesc] = useState(description)
    const debouncedDesc = useDebounce(desc, 300);
    useEffect(() => {
        if (debouncedDesc) {
            onUpdateCollection({ description: debouncedDesc })
        }
    }, [debouncedDesc]);
    return (
        <CollectionDescStyle>
            <label htmlFor="collection-description">Collection description:</label>
            <textarea id="collection-description" name="collection-description"
                rows={2}
                placeholder="Brief info about this NFT collection"
                value={desc}
                onChange={(e: any) => setDesc(e.target.value)}
            />
        </CollectionDescStyle>
    )
}
export default CollectionDesc
const CollectionDescStyle = memo(styled.div`
    label{
        font-size:12px;
        display: block;
    }   
    textarea{
        resize: none;
        border-radius:5px;
        background:#f1f1f1;
        border:solid 1px #f1f1f1;
        padding:3px 5px;
        margin-top:5px;
        width:100%;
    }
`)
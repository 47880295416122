import BigNumber from "bignumber.js"
import { ILayer, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { useDebounce } from "helpers/hooks/useDebounce"
import { memo, useContext, useEffect, useState } from "react"
import styled from "styled-components"

interface IRarityGauge {
    id: string
}
const RarityGauge = ({ id }: IRarityGauge) => {
    const { state: { layer }, onUpdateWeight } = useContext(NFTGeneratorContext)
    const [value, setValue] = useState((layer[layer.findIndex((item: ILayer) => item.id === id)].weight) + '')
    const debouncedValue = useDebounce(value, 500);
    useEffect(() => {
        if (debouncedValue) {
            onUpdateWeight(id, +debouncedValue)
        }
    }, [debouncedValue]);
    return (
        <RarityGaugeStyle>
            <input value={+new BigNumber(value).multipliedBy(100).precision(6)}
                className="rgs_input"
                onChange={(e) => {
                    if (+e.target.value >= 0 && +e.target.value <= 100) {
                        setValue(+new BigNumber(e.target.value).dividedBy(100).precision(6) + '')
                    }
                }}
            />
            <input type="range"
                min="0"
                max="100"
                step="0.01"
                value={+new BigNumber(value).multipliedBy(100).precision(6)}
                className="rga_gauge"
                onChange={(e) => setValue(+new BigNumber(e.target.value).dividedBy(100).precision(6) + '')}
            />
        </RarityGaugeStyle>
    )
}
export default RarityGauge
const RarityGaugeStyle = memo(styled.div`
    display: flex;
    align-items: center;
    .rgs_input{
        width: 100%;
        height: 30px;
        background: #f1f1f1;
        border-radius: 5px;
        padding: 3px 5px;
        border: solid 1px #f1f1f1;
        margin-right:10px;
    }
`)
import { NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { renderCanvasImage, renderCanvasImageWithBlob } from "helpers/renderCanvasImage"
import { memo, useContext, useEffect } from "react"
import styled from "styled-components"

interface ICollectionListItem {
    nftImages: string[],
    id: string,
    name: string
}
const CollectionListItem = ({ nftImages, id, name }: ICollectionListItem) => {
    const { state: { layer } } = useContext(NFTGeneratorContext)
    useEffect(() => {
        renderCanvasImageWithBlob({ nftImages, id, size: [250, 250] })
    }, [nftImages, id, name])
    return (
        <CollectionListItemStyle>
            <div className="clis">
                <canvas id={id} />
            </div>
            <p>{name}</p>
        </CollectionListItemStyle>
    )
}
export default CollectionListItem
const CollectionListItemStyle = memo(styled.div`
    .clis{
        background: #f3f4f6;
        border-radius: 5px;
        width: max-content;
        max-width:100%;
        canvas{
            max-width:100%;
        }
    }
    p{
        margin-top:10px;
        color:#9f9f9f;
        text-align: center;
    }
`)
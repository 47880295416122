import { memo } from "react"
import styled from "styled-components"
import HeaderGenerate from "./headerGenerate";
import HeaderHome from "./headerHome";
import logo from 'assets/images/logo.png'
import { Link } from "react-router-dom";
interface IHeadWrap {
    children: any
}
const HeadWrap = ({ children }: IHeadWrap) => {
    const path = window.location.pathname;
    // console.log('path', path)
    return (
        <HeadWrapStyle>
            <div className="hws_head">
                <div className="hwsh_logo">
                    <Link to="/"><img src={logo} alt="logo" /></Link>
                </div>
                <div className="hwsh_main">
                    {path === '/' && <HeaderHome />}
                    {path === '/generate' && <HeaderGenerate />}
                </div>
            </div>
            <div className="hws_content">
                {children}
            </div>
        </HeadWrapStyle>
    )
}

export default HeadWrap
const HeadWrapStyle = memo(styled.div`
    height:100%;
    overflow: hidden;
    .hws_head{
        height:80px;
        display: flex;
        border-bottom:solid 1px #c7c7c7;
        .hwsh_logo{
            width:10.7vw;
            height:100%;
            background:#2f9c95;
            padding:10px;
            text-align:center;
            img{
                max-height:100%;
            }
        }
        .hwsh_main{
            height: 100%;
            width:89.3vw;
        }
    }
    .hws_content{
        height:calc(100% - 80px);
        overflow: hidden;
        >div{
            height:100%;
        }
    }
`)
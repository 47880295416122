import { ILayer, IPrototype, IPrototypeItem, ITraitImage, ITraits, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { TMetadataType } from "types"
export interface IAttributeItem {
    ["trait_type"]: string,
    ["value"]: string,
}
export interface IPropertyItem {
    ["trait_type"]: string,
    ["value"]: string,
}
interface ICreateMetadata {
    type: TMetadataType,
    dataTrait: any,
    name?: string,
    description?: string,
    compiler?: string,
}
export const createMetadata = ({ type, dataTrait, name, description, compiler }: ICreateMetadata) => {
    let jsonMeta = {
        name: '',
        image: '',
        description: '',
        external_url: '',
        attributes: [],
        compiler: ''
    } as any;
    if (name) jsonMeta.name = name;
    if (description) jsonMeta.description = description;
    // console.log('dataTrait', dataTrait)
    if (type === 'erc721') {
        let attributes = [] as IAttributeItem[];
        Object.keys(dataTrait).forEach((item: any) => {
            let type = '',
                val = ';'
            Object.keys(dataTrait[item]).forEach((i: any) => {
                type = i;
                val= dataTrait[item][i]
            })
            attributes.push({
                trait_type: type,
                value: val
            })
        })
        jsonMeta.attributes = attributes;
    };
    if (type === 'erc1155') {
        let attributes = [] as any[];
        Object.keys(dataTrait).forEach((item: any) => {
            attributes.push({
                trait_type: item,
                value: dataTrait[item]
            })
        })
        jsonMeta.attributes = attributes;
    };
    if (compiler) jsonMeta.compiler = compiler;
    return jsonMeta
}
import { ILayer, IPrototypeItem, ITraits, NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { memo, useCallback, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import LayoutListItem from "./LayerListItem"
import { MdAddCircleOutline, MdDragHandle } from 'react-icons/md'
import Sortable from 'sortablejs';
import { motion } from "framer-motion"
import { useHover } from 'helpers/hooks/useHover'
const LayerList = () => {
    const { state: { layer, activeLayer, prototypes },
        onAddLayer,
        onUpdateActiveLayout,
        onChangeLayerIndex
    } = useContext(NFTGeneratorContext)
    const [dragItem, setDragItem] = useState({
        oldIndex: undefined as undefined | number,
        newIndex: undefined as undefined | number
    })
    useEffect(() => {
        if (layer.length > 0) {
            const idDrag = document.getElementById('dragList');
            idDrag && Sortable.create(
                idDrag,
                {
                    direction: 'vertical',
                    handle: ".llsli",
                    animation: 100,
                    onEnd: (event) => {
                        const oldIndex = event.oldIndex;
                        const newIndex = event.newIndex;
                        setDragItem({ oldIndex, newIndex })
                    },
                },
            );
        }
    }, [layer])
    useEffect(() => {
        const { oldIndex, newIndex } = dragItem;
        let tempArrLayer = [] as ILayer[],
            tempActive = null as IPrototypeItem | null,
            tempArrList = [] as IPrototypeItem[],
            tempArrSaved = [] as IPrototypeItem[],
            itemActive = prototypes.active, // IPrototypeItem
            itemSetList = prototypes.list, // IPrototypeItem[]
            itemSetSaved = prototypes.saved; // IPrototypeItem[]
        // console.log('TTT', oldIndex, newIndex)
        if (oldIndex !== undefined && newIndex !== undefined && oldIndex !== newIndex) {
            let objAId = '',
                objBId = '';
            for (let i = 0; i < layer.length; i++) {
                if (i === newIndex) {
                    tempArrLayer.push(layer[oldIndex])
                    objAId = layer[oldIndex].id;
                }
                else if (i === oldIndex) {
                    tempArrLayer.push(layer[newIndex])
                    objBId = layer[newIndex].id;
                }
                else {
                    tempArrLayer.push(layer[i])
                }
            }

            const rearrangement = (objAId: string, objBId: string, needUpdateArr: ITraits[]) => {
                let tempArr = [...needUpdateArr] as ITraits[];
                const idxOld = needUpdateArr.findIndex((item: ITraits) => item.layer === objAId)
                const idxNew = needUpdateArr.findIndex((item: ITraits) => item.layer === objBId)
                if (idxOld > -1 && idxNew > -1) {
                    tempArr.splice(idxOld, 1, needUpdateArr[idxNew]);
                    tempArr.splice(idxNew, 1, needUpdateArr[idxOld]);
                }
                return tempArr;
            }
            if (objAId !== '' && objBId !== '') {
                // console.log('traitsList', itemSetList)
                if (itemActive?.traits) {
                    tempActive = {
                        ...itemActive,
                        traits: rearrangement(objAId, objBId, itemActive?.traits)
                    }
                }
                for (let r = 0; r < itemSetList.length; r++) {
                    const shuffedArr = rearrangement(objAId, objBId, itemSetList[r].traits);
                    tempArrList.push({ ...itemSetList[r], traits: shuffedArr })
                }
                for (let r = 0; r < itemSetSaved.length; r++) {
                    const shuffedArr = rearrangement(objAId, objBId, itemSetSaved[r].traits);
                    tempArrSaved.push({ ...itemSetSaved[r], traits: shuffedArr })
                }
            }
            onChangeLayerIndex(tempArrLayer, tempArrList, tempArrSaved, tempActive);
        }
    }, [dragItem])
    return (
        <LayerListStyle>
            <div className="lls_list">
                <div className="lls" id="dragList">
                    {layer.length > 0 && layer.map((item, index: number) => {
                        return (
                            <div key={`${item.id}`}
                                className={`llsl_item ${activeLayer === item.id ? 'active' : ''}`}
                            >
                                <LayoutListItem
                                    name={item.name}
                                    id={item.id}
                                    active={activeLayer === item.id}
                                    handleClick={() => onUpdateActiveLayout(item.id)}
                                />
                                <motion.div className="llsli">
                                    <MdDragHandle size={22} />
                                </motion.div>
                            </div>
                        )
                    })}
                </div>
                <div className="llsl_item add-layer" onClick={() => onAddLayer("")}>
                    <MdAddCircleOutline size={30} />
                    <p>Add layer</p>
                </div>
            </div>
            <div className="lls">

            </div>
        </LayerListStyle >
    )
}
export default LayerList
const LayerListStyle = memo(styled.div`
    .lls_list{
        .llsl{
            border-bottom:solid 1px #c7c7c7c7;
        }
        .llsl_item{
            display:flex;
            align-items: stretch;
            justify-content: space-between;
            position:relative;
            &:not(.active){
                &:hover{
                    background:#40c9a2;
                }
            }
            &.active{
                background:#664147;
                .llsli{
                    svg{
                        /* color:#664147; */
                    }
                }
            }
            &:not(.add-layer){
                border-bottom:solid 1px #c7c7c7;
            }
            &.add-layer{
                color:#fff;
                text-align:center;
                padding:10px;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                p{
                    margin-top:10px;
                    font-size:12px;
                }
            }
            .llsli{
                cursor: grab;
                position:absolute;
                top:10px;
                left:10px;
                svg{
                    color:#fff;
                }
            }
        }
    }
`)
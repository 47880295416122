import CollectionInfo from "conponents/collection/CollectionInfo"
import { NFTGeneratorContext } from "contexts/NFTGeneratorContext"
import { lazy, memo, Suspense, useContext, useState } from "react"
import LeftSideTest from "conponents/testing/LeftSideTest"
import RandomizePreview from "conponents/testing/RandomizePreview"
import styled from "styled-components"
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "conponents/core/ErrorFallBack"
import Loading from "conponents/core/Loading"
import Generator from "pages/generator"
import Home from "pages/home"
import HeadWrap from "conponents/header"
import ReactTooltip from "react-tooltip"

const App = () => {
  return (
    <AppStyle>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Outlet />} >
                <Route index element={<HeadWrap><Home /></HeadWrap>} />
                <Route path="/generate" element={<HeadWrap><Generator /></HeadWrap>} />
              </Route>
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
      <ReactTooltip />
    </AppStyle >
  );
}
export default App;
const AppStyle = memo(styled.div`
    height:100vh;
    overflow: hidden;
`)

import { memo } from 'react'
import styled from 'styled-components'
import Loading from './Loading';

interface StyledLoadingProps {
    color: string;
    size: number;
}
const LoadingScreen = () => {
    return (
        <LoadingScreenWrap>
            <Loading/>
        </LoadingScreenWrap>
    )
}
export default memo(LoadingScreen)
const LoadingScreenWrap = memo(styled.div`
    position:fixed;
    display:flex;
    align-items:center;
    justify-content:center;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background:#fff;
    z-index:9;
    text-align:center;
`)
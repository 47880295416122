import BigNumber from 'bignumber.js';
import { createContext, useReducer } from 'react'
export type TDimen = [number, number]
type TAction =
    { type: "addLayer", name: string } |
    { type: "removeLayer", id: string } |
    { type: "updateNameLayer", id: string, name: string } |
    { type: "updateImages", id: string, images: Array<any> } |
    { type: "removeImage", id: string, images: Array<any> } |
    { type: "updateWeight", id: string, weight: number } |
    { type: "updateActiveLayout", id: string } |
    { type: "updateImageSet", images: Array<any> } |
    { type: "updatePrototypes", newPrototypes: IPrototype } |
    { type: "updateActivePrototype", newActive: IPrototypeItem | null } |
    { type: "updateInclusive", id: string, isInclude: boolean } |
    { type: "updateCollection", name?: string, description?: string, size?: TDimen, supply?: number, extenalLink?: string } |
    { type: "updateSupply", supply: number } |
    { type: "addNewPrototype" } |
    { type: "changePrototypeTitle", newTitle: string; } |
    { type: "changeLayerIndex", layer: ILayer[], list: IPrototypeItem[], saved: IPrototypeItem[], active: IPrototypeItem | null } |
    { type: "updateGeneratedCollection", imageSet: any, metadata?: any } |
    { type: "reloadGeneratedCollection" } |
    { type: "updateImageRarityInLayer", layerUpdated: ILayer[] } |
    { type: "updateMetadata", newMetadata: any } |
    { type: "newCollection" }
export interface IUpdateCollection {
    name?: string,
    description?: string,
    size?: TDimen,
    supply?: number,
    extenalLink?: string
}
export interface ITraitImage {
    id: string,
    name: string,
    image: string,
    weight: number
}
export interface ILayer {
    id: string,
    name: string,
    images: ITraitImage[],
    weight: number
}
export interface ITraits {
    layer: string;
    id: string;
}
export interface IPrototypeItem {
    id: string;
    title: string;
    inclusive: boolean;
    imgUri: string;
    traits: ITraits[]
}
export interface IPrototype {
    active: IPrototypeItem | null;
    list: IPrototypeItem[];
    saved: IPrototypeItem[]
}
interface IInititalState {
    activeLayer: string,
    collection: {
        id: string,
        name: string,
        description: string,
        size: [number, number],
        supply: number,
        extenalLink: string
    },
    generated: {
        imageSet: any,
        metadata: any,
        reload: boolean
    },
    layer: ILayer[],
    prototypes: IPrototype
}
const initialState: IInititalState = {
    activeLayer: '',
    collection: {
        id: `collection_${new Date().getTime()}`,
        name: '',
        description: '',
        size: [500, 500],
        supply: 50,
        extenalLink: ''
    },
    generated: {
        imageSet: {},
        metadata: {},
        reload: false
    },
    layer: [],
    prototypes: {
        active: null,
        list: [],
        saved: []
    }
}
export const NFTGeneratorContext = createContext({
    state: initialState,
    onAddLayer: (name: string) => { },
    onUpdateImages: (id: string, images: Array<any>) => { },
    onUpdateNameLayer: (id: string, name: string) => { },
    onRemoveLayer: (id: string) => { },
    onUpdateActiveLayout: (id: string) => { },
    onUpdateWeight: (id: string, weight: number) => { },
    onUpdateCollection: ({ name, description, size, supply }: IUpdateCollection) => { },
    onUpdatePrototypes: (newPrototypes: IPrototype) => { },
    onUpdateActivePrototype: (newActive: IPrototypeItem | null) => { },
    onUpdateInclusive: (id: string, isInclude: boolean) => { },
    onUpdateSupply: (supply: number) => { },
    onAddPrototype: () => { },
    onChangePrototypeTitle: (newTitle: string) => { },
    onChangeLayerIndex: (layer: ILayer[], list: IPrototypeItem[], saved: IPrototypeItem[], active: IPrototypeItem | null) => { },
    onUpdateGenerated: (imageSet: any, metadata?: any) => { },
    onUpdateMetadata: (newMetadata: any) => { },
    onUpdateImageRarityInLayer: (layerUpdated: ILayer[]) => { },
    regenerateCollection: () => { },
    startNewCollection: () => { }
})
const NFTGeneratorContextWrap = ({ children }: any) => {
    const reducer = (state: IInititalState, action: TAction) => {
        switch (action.type) {
            case "addLayer": {
                const { name } = action;
                let addObj = { ...state }
                const newId = new Date().getTime();
                const layerId = `layer_${newId}`;
                const newProt = {
                    id: `prot_${newId}`,
                    title: '',
                    inclusive: false,
                    imgUri: '',
                    traits: []
                }
                if (state.layer.length === 0) {
                    addObj.prototypes = {
                        active: newProt,
                        list: [newProt],
                        saved: []
                    }
                }
                const newLayer = {
                    id: layerId,
                    name,
                    images: [],
                    weight: 1
                }
                const newState = [...state.layer, newLayer] as any;
                return {
                    ...addObj,
                    layer: newState,
                    activeLayer: layerId
                }
            }
            case "updateImages": {
                const { id, images } = action;
                const prevState = [...state.layer]
                const findIndex = [...state.layer].findIndex((item: ILayer) => item.id === id)
                if (findIndex > -1) {
                    prevState[findIndex].images = images;
                }
                return { ...state, layer: prevState }
            }
            case "updateNameLayer": {
                const { id, name } = action;
                const prevState = [...state.layer]
                const findIndex = [...state.layer].findIndex((item: ILayer) => item.id === id)
                if (findIndex > -1) {
                    prevState[findIndex].name = name
                }
                return { ...state, layer: prevState }
            }
            case "updateWeight": {
                const { id, weight } = action;
                const prevState = [...state.layer]
                const findIndex = [...state.layer].findIndex((item: ILayer) => item.id === id)
                if (findIndex > -1) {
                    prevState[findIndex].weight = weight
                }
                return { ...state, layer: prevState }
            }
            case "removeLayer": {
                const { id } = action;
                const prevState = [...state.layer]
                const findIndex = [...state.layer].findIndex((item: ILayer) => item.id === id)
                let tempList = state.prototypes.list,
                    tempActive = state.prototypes.active,
                    tempSaved = state.prototypes.saved;
                if (tempActive !== null) {
                    for (let i = 0; i < tempList.length; i++) {
                        const idxNeedRemove = tempList[i].traits.findIndex((item: ITraits) => item.layer === id);
                        tempList[i].traits.splice(idxNeedRemove, 1)
                    }
                    for (let i = 0; i < tempSaved.length; i++) {
                        const idxNeedRemove = tempSaved[i].traits.findIndex((item: ITraits) => item.layer === id);
                        tempSaved[i].traits.splice(idxNeedRemove, 1)
                    }
                    const idxActiveNeedRemove = tempActive.traits.findIndex((item: ITraits) => item.layer === id);
                    tempActive.traits.splice(idxActiveNeedRemove, 1)
                }
                if (findIndex > -1) {
                    prevState.splice(findIndex, 1)
                }
                return {
                    ...state,
                    layer: prevState,
                    activeLayer: findIndex > 0 ? state.layer[findIndex].id : state.layer[0].id,
                    prototypes: {
                        active: tempActive,
                        list: tempList,
                        saved: tempSaved
                    }
                }
            }
            case "updateActiveLayout": {
                const { id } = action;
                return { ...state, activeLayer: id }
            }
            case "updatePrototypes": {
                const { newPrototypes } = action;
                return { ...state, prototypes: newPrototypes }
            }
            case "updateActivePrototype": {
                const { newActive } = action;
                return {
                    ...state,
                    prototypes: {
                        ...state.prototypes,
                        active: newActive
                    }
                }
            }
            case "updateInclusive": {
                const { id, isInclude } = action;
                const newList = [...state.prototypes.list],
                    newSaved = [...state.prototypes.saved];
                newList[newList.findIndex((item: IPrototypeItem) => item.id === id)].inclusive = isInclude;
                newSaved[newSaved.findIndex((item: IPrototypeItem) => item.id === id)].inclusive = isInclude;
                return {
                    ...state,
                    prototypes: {
                        active: {
                            ...state.prototypes.active,
                            inclusive: isInclude
                        },
                        list: newList,
                        saved: newSaved
                    }
                }
            }
            case "addNewPrototype": {
                const newProt = {
                    id: `prot_${new Date().getTime()}`,
                    title: '',
                    inclusive: false,
                    imgUri: '',
                    traits: []
                }
                let list = state.prototypes?.list || [];
                list.push(newProt);
                return {
                    ...state,
                    prototypes: {
                        ...state.prototypes,
                        active: newProt,
                        list
                    }
                }
            }
            case "changePrototypeTitle": {
                const { newTitle } = action;
                let tempList = [] as IPrototypeItem[];
                let tempActive = null as any;
                if (state.prototypes) {
                    tempList = state.prototypes.list;
                    tempActive = { ...state.prototypes.active }
                    tempActive.title = newTitle;
                }
                if (state.prototypes && state.prototypes.active) {
                    tempList.splice(
                        tempList.findIndex((item: IPrototypeItem) => item.id === state.prototypes?.active?.id), 1, tempActive
                    )
                }
                return {
                    ...state,
                    prototypes: {
                        ...state.prototypes,
                        active: tempActive,
                        list: tempList
                    }
                }
            }
            case "updateCollection": {
                const { name, description, size, supply, extenalLink } = action;
                let newCollection = {
                    ...state.collection
                }
                if (name) newCollection.name = name;
                if (description) newCollection.description = description;
                if (size) newCollection.size = size;
                if (supply) newCollection.supply = supply;
                if (extenalLink) newCollection.extenalLink = extenalLink;
                return { ...state, collection: newCollection }
            }
            case 'updateImageSet':
                const { images } = action;
                return {
                    ...state,
                    layer: { ...state.layer, images }
                }
            case 'updateSupply':
                const { supply } = action;
                return { ...state, supply }
            case 'changeLayerIndex':
                const { layer, list, saved, active } = action;
                let newState = {
                    ...state,
                    layer,
                    prototypes: {
                        ...state.prototypes,
                        list,
                        saved
                    }
                }
                if (active) newState.prototypes.active = active;
                return newState
            case 'updateGeneratedCollection':
                const { imageSet, metadata } = action;
                let newUpdate = {
                    ...state,
                    generated: {
                        ...state.generated,
                        imageSet,
                        reload: false
                    }
                }
                if (metadata) {
                    newUpdate.generated.metadata = metadata
                }
                return newUpdate
            case 'reloadGeneratedCollection':
                return {
                    ...state,
                    generated: {
                        ...state.generated,
                        reload: true
                    }
                }
            case 'updateMetadata':
                const { newMetadata } = action;
                return {
                    ...state,
                    generated: {
                        ...state.generated,
                        metadata: newMetadata
                    }
                }
            case 'updateImageRarityInLayer':
                const { layerUpdated } = action;
                // const { layerId, imageId, newWeight } = action;
                // const layerIdx = [...state.layer].findIndex((item: ILayer) => item.id === layerId);
                // let layerUpdated = [...state.layer];
                // if (layerIdx > -1) {
                //     let layerImages = [...state.layer][layerIdx].images;
                //     const imageIdx = layerImages.findIndex((item: ITraitImage) => item.id === imageId);
                //     const modifiedVal =
                //         // (layerImages[imageIdx].weight * 100 * layerImages.length) - (newWeight * 100 * layerImages.length);
                //         +new BigNumber(layerImages[imageIdx].weight).multipliedBy(100).multipliedBy(layerImages.length).precision(2) -
                //         +new BigNumber(newWeight).multipliedBy(100).multipliedBy(layerImages.length).precision(2);

                //     console.log('%c LLLLLLLLLL', 'color:orange', 'modifiedVal: ' + modifiedVal, '  --- newWeight: ' + newWeight)
                //     for (let i = 0; i < layerImages.length; i++) {
                //         if (i === imageIdx) {
                //             layerImages[i].weight = newWeight;
                //         } else {
                //             const itemWeight = layerImages[i].weight;
                //             console.log('%c XXXXXXXXX', 'color:red', itemWeight, layerImages[i].name)
                //             const data = new BigNumber(((itemWeight * (layerImages.length * 100)) + (modifiedVal / (layerImages.length - 1)))).dividedBy((layerImages.length * 100)).precision(2);
                //             console.log('%c YYYYYY', 'color:cyan', 'data', +data)
                //             layerImages[i].weight = +data < 0 ? 0 : +data;
                //             //         const data = ((itemWeight * (layerImages.length * 100)) + (modifiedVal / (layerImages.length - 1))) / 100;
                //         }
                //     }
                //     layerUpdated[layerIdx].images = layerImages;
                // }
                return {
                    ...state,
                    layer: layerUpdated
                }
            case 'newCollection': {
                return initialState
            }
            default:
                return state
        }
    }
    const [state, dispatch] = useReducer(reducer, initialState)
    const onAddLayer = (name: string) => {
        dispatch({ type: "addLayer", name })
    }
    const onUpdateImages = (id: string, images: Array<any>) => {
        dispatch({ type: "updateImages", id, images })
    }
    const onUpdateNameLayer = (id: string, name: string) => {
        dispatch({ type: "updateNameLayer", id, name })
    }
    const onUpdateWeight = (id: string, weight: number) => {
        dispatch({ type: "updateWeight", id, weight })
    }
    const onRemoveLayer = (id: string) => {
        dispatch({ type: "removeLayer", id })
    }
    const onUpdateActiveLayout = (id: string) => {
        dispatch({ type: "updateActiveLayout", id })
    }
    const onUpdatePrototypes = (newPrototypes: IPrototype) => {
        dispatch({ type: "updatePrototypes", newPrototypes })
    }
    const onUpdateActivePrototype = (newActive: IPrototypeItem | null) => {
        dispatch({ type: "updateActivePrototype", newActive })
    }
    const onUpdateInclusive = (id: string, isInclude: boolean) => {
        dispatch({ type: "updateInclusive", id, isInclude })
    }
    const onUpdateSupply = (supply: number) => {
        dispatch({ type: "updateSupply", supply })
    }
    const onAddPrototype = () => {
        dispatch({ type: "addNewPrototype" })
    }
    const onChangePrototypeTitle = (newTitle: string) => {
        dispatch({ type: "changePrototypeTitle", newTitle })
    }
    const onChangeLayerIndex = (layer: ILayer[], list: IPrototypeItem[], saved: IPrototypeItem[], active: IPrototypeItem | null) => {
        dispatch({ type: "changeLayerIndex", layer, list, saved, active })
    }
    const onUpdateGenerated = (imageSet: any, metadata?: any) => {
        dispatch({ type: "updateGeneratedCollection", imageSet, metadata })
    }
    const onUpdateMetadata = (newMetadata: any) => {
        dispatch({ type: "updateMetadata", newMetadata })
    }
    const onUpdateImageRarityInLayer = (layerUpdated: ILayer[]) => {
        dispatch({ type: "updateImageRarityInLayer", layerUpdated })
    }
    const onUpdateCollection = ({ name, description, size, supply, extenalLink }: IUpdateCollection) => {
        dispatch({
            type: "updateCollection",
            name, description, size, supply, extenalLink
        })
    }
    const regenerateCollection = () => {
        dispatch({ type: "reloadGeneratedCollection" })
    }
    const startNewCollection = () => {
        dispatch({ type: "newCollection" })
    }
    console.log('layer', state.prototypes.saved)
    return (
        <NFTGeneratorContext.Provider value={{
            state,
            onAddLayer,
            onUpdateImages,
            onUpdateNameLayer,
            onRemoveLayer,
            onUpdateWeight,
            onUpdateActiveLayout,
            onUpdatePrototypes,
            onUpdateActivePrototype,
            onUpdateInclusive,
            onUpdateCollection,
            onUpdateSupply,
            onAddPrototype,
            onChangePrototypeTitle,
            onChangeLayerIndex,
            onUpdateGenerated,
            onUpdateImageRarityInLayer,
            onUpdateMetadata,
            regenerateCollection,
            startNewCollection
        }}>
            {children}
        </NFTGeneratorContext.Provider>
    )
}

export default NFTGeneratorContextWrap